@import url('https:fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');


$ch-family: "STXihei", HEITI TC, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;
$content-family: "STXihei", HEITI TC, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;
$en-family: 'Roboto', sans-serif;
$bree-family: 'Roboto', sans-serif;
$dft_r5: "STXihei", HEITI TC, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;
$jk: "STXihei", HEITI TC, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;
$ench: 'Roboto', "STXihei", HEITI TC, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;


html,body{
	-webkit-text-size-adjust: 100%;   // for mobile fontsize
}

a{
	text-decoration: none;
	color: inherit;
	img{border: 0;}
}

:focus{
	outline: 0;
}

button{
	background-color: transparent;
	border: 0;
}

*{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

img{
	vertical-align: middle; //remove bottom space
	max-width: 100%;
	// max-height: 100%;
}
iframe{
	max-width: 100%;
	@include breakpoint(medium down){
		height: auto;
	};
}

/* override the default IOS styles */
input, textarea{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}


/*

 \
 _\,,
"-=\~     _
   \\~___( ~
  _|/---\\_
  \        \	base.scss end

*/
