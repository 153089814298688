@import 'foundation';
@import "reset";
@import "base";
@import "public";
// --------------- custom faoundation settings here --------------------
$width: 1100px;
$space: 90px;
$spaceMobile: 20px;
/* custom faoundation settings here*/
$global-margin: 22px;
$global-padding: 22px;
$prototype-spacing-breakpoints: true;
$prototype-spacers-count: 8;
/* $grid-container-padding: 0;*/
$responsive-embed-margin-bottom: 0;
$breakpoint-classes: (small medium large xlarge xxlarge);
$responsive-embed-ratios: (
	default: 16 by 9,
	vertical: 9 by 16,
	panorama: 256 by 81,
	square: 1 by 1,
	custom: 16 by 7,
);
$grid-container: $width + $space * 2;
/* $grid-margin-gutters: (*/
/* 	small: $spaceMobile * 2,*/
/* 	large: $space * 2,*/
/* );*/
/* $grid-padding-gutters: (*/
/* 	small: $spaceMobile * 2,*/
/* 	large: $space * 2,*/
/* );*/
$grid-container-padding: (
	small: $spaceMobile * 2,
	large: $space * 2,
);
@include foundation-xy-grid-classes;
@include foundation-prototype-spacing;
@include foundation-flex-classes;
@include foundation-responsive-embed;
@include foundation-visibility-classes;
@include foundation-text-alignment;
$blue: #6786b8;
$blue1: #5d89b4;
$blue-deep: #5b89b4;
$mobile-media: 767px;
/* -------------------------------------------------------*/
body {
	overflow-x: hidden;
	font-family: $content-family;
}
.tempWrap{
	position: relative;
	width: 100%;
	height: 100vh;
}
@-webkit-keyframes wave{
	0%{
		stroke-dashoffset: 50;
	}
	100%{
		stroke-dashoffset: 0;
	}
}
@keyframes wave{
	0%{
		stroke-dashoffset: 50;
	}
	100%{
		stroke-dashoffset: 0;
	}
}
.temp-container{
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
	@include breakpoint(medium down){
		width: 100%;
		padding: 0 30px;
	};
	.anihehehehe{
		width: 457px;
		height: 238px;
		position: relative;
		margin-bottom: 30px;
		@include breakpoint(medium down){
			margin-bottom: -11px;
			-webkit-transform: scale(0.65);
			        transform: scale(0.65);
			-webkit-transform-origin: 8% center;
			        transform-origin: 8% center;
		};
		.cloud-1{
			position: absolute;
			top: 46px;
		    left: 2px;
		    -webkit-transform: translate(30px, 0px);
		            transform: translate(30px, 0px);
		    opacity: 0;
		}
		.cloud-2{
			position: absolute;
			top: 35px;
		    left: 36px;
		    -webkit-transform: translate(30px, 0px);
		            transform: translate(30px, 0px);
		    opacity: 0;
		}
		.cloud-3{
			position: absolute;
			top: 0;
		    left: 278px;
		    -webkit-transform: translate(30px, 0px);
		            transform: translate(30px, 0px);
		    opacity: 0;
		}
		.sun{
			position: absolute;
			top: 38px;
		    left: 155px;
		    -webkit-transform: translate(90px, 40px);
		            transform: translate(90px, 40px);
		    opacity: 0;
		}
		.bg{
			position: absolute;
			top: 17px;
			left: 0;
		}
		.wave{
			position: absolute;
			top: 201px;
			left: 61px;
			.st0 {
			    fill: none;
			    stroke: #5B89B4;
			    stroke-width: 1.2153;
			    stroke-linecap: round;
			    stroke-linejoin: round;
			    stroke-miterlimit: 10;
			    stroke-dasharray: 50;
			    stroke-dashoffset: 50;
			    &:nth-child(1){
			    	-webkit-animation: wave 3s both infinite linear;
			    	        animation: wave 3s both infinite linear;
			    }
			    &:nth-child(2){
			    	-webkit-animation: wave 3s both infinite linear;
			    	        animation: wave 3s both infinite linear;
			    }
			}
		}
		.man-1{
			position: absolute;
			top: 180px;
		    left: 173px;
		    z-index: 1;
		    -webkit-transform: translate(0px, 20px);
		            transform: translate(0px, 20px);
		    opacity: 0;
		}
		.man-2{
			position: absolute;
			top: 182px;
			left: 197px;
			-webkit-transform: translate(0px, 20px);
			        transform: translate(0px, 20px);
		    opacity: 0;
		}
		.man-3{
			position: absolute;
			top: 183px;
		    left: 318px;
		    -webkit-transform: translate(0px, 20px);
		            transform: translate(0px, 20px);
		    opacity: 0;
		}
		.hi{
			position: absolute;
			top: 155px;
			left: 362px;
			-webkit-transform: translate(-8px, 14px);
			        transform: translate(-8px, 14px);
			opacity: 0;
		}
	}
	.company{
		font-family: $en-family;
		font-size: 48px;
		color: #5d89b4;
		// border-bottom: 4px double #5d89b4;
		// padding-bottom: 3px;
		margin-right: 30px;
		@include breakpoint(medium down){
			padding-bottom: 8px;
			margin-right: 0;
			margin-bottom: 20px;
		};
	}
	.lanList{
		a{
			font-family: $content-family;
			font-weight: 700;
			font-size: 22px;
			color: #5d89b4;
			width: 76px;
			height: 46px;
			border: 2px solid #5d89b4;
			border-radius: 200px;
			transition: all .5s;
			@include mr(15px);
			&:hover{
				color: #fff;
				background-color: #5d89b4;
			}
		}
	}
}
.Wrap {
	margin-top: 100px;
	letter-spacing: 2px;
}
.doubleBlue {
	border-top: 1px solid #6786b8;
	width: 100%;
	border-bottom: 1px solid #6786b8;
	height: 10px;
	margin: 40px auto;
}
.doubleWhite {
	border-top: 1px solid #fff;
	width: 100%;
	border-bottom: 1px solid #fff;
	height: 10px;
	margin: 40px auto;
}
/*////////////======== topmenu ========//////////////*/
.topmenuWrap {
	position: fixed;
	top: 0;
    width: 100%;
    height: 58px;
    background-color: #fff;
    z-index: 4;
    .enenlogo{
    	position: absolute;
    	left: 40px;
    	top: 20px;
    	@include breakpoint(medium down){
    		left: 20px;
		    width: 64px;
    	};
    }
}
.logo {
	width: 182px;
	height: 34px;
	margin: 12px auto;
	@include breakpoint(medium down){
		width: 130px;
		height: 25px;
		margin: 16px auto;
		position: relative;
		left: 25px;
	};
	.logo-inner{
		a{
			width: 182px;
			height: 34px;
			@include breakpoint(medium down){
				width: 130px;
				height: 25px;
			};
		}
	}
}
.topmenu {
	position: absolute;
    right: 25px;
    top: 16px;
}
.menutext {
	position: absolute;
    right: 65px;
    top: 4px;
    font-family: $bree-family;
    letter-spacing: 5px;
    font-size: 19px;
    color: $blue;
    @include breakpoint(medium down) {
    	display: none;
    }
}
.hamburgerWrap {
	cursor: pointer;
	.hamburger-1, .hamburger-2 , .hamburger-3 {
		display: block;
		width: 40px;
		height: 2px;
		background-color: $blue;
		margin-bottom: 9px;
		transition: 0.4s;
	}
}
.hamburgerWrap.change{
	cursor: pointer;
	.hamburger-1 {
	    -webkit-transform: rotate(-45deg) translate(-11px, 8px);
	    transform: rotate(-45deg) translate(-7px, 8px);
	}
	.hamburger-2 {opacity: 0;}
	.hamburger-3 {
	    -webkit-transform: rotate(45deg) translate(-9px, -8px);
	    transform: rotate(45deg) translate(-8px, -8px);
	}
}
/* wwwwwwwwww========= menu ========wwwwwwwwwwww //*/
.bgw {
	display: none;
	position: fixed;
	top: 0;
	margin: 0;
    width: 1920px;
    height: 1080px;
    z-index: 4;
    background-color: #fff;
    opacity: 0.9;
}
.TopmenuPosition {
	z-index: 10;
	background-color: rgb(91, 137, 180);
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	width: 1280px;
	height: auto;
	display: none;
	@include breakpoint(medium down) {
		width: 100%;
		-webkit-transform: translate(0%, 0%);
		        transform: translate(0%, 0%);
		top: 0%;
		left: 0%;
		height: 100%;
		overflow-y: auto;
	}
	&:after {
		content: '';
	    width: calc(100% - 88px);
	    height: calc(100% - 80px);
	    position: absolute;
	    top: 40px;
	    left: 44px;
	    border: 1px solid #fff;
	    transition: all .5s;
	    z-index: -1;
	    @include breakpoint(medium down) {
	    	content: none;
	    }
	}
	&:before {
		content: '';
	    width: calc(100% - 68px);
	    height: calc(100% - 60px);
	    position: absolute;
	    top: 30px;
	    left: 34px;
	    border: 1px solid #fff;
	    transition: all .5s;
	    z-index: -1;
	    @include breakpoint(medium down) {
	    	display: none;
		    // width: calc(100% - 24px);
		    // height: 100%;
		    // top: 14px;
		    // left: 12px;
	    }
	}
	.back {
		z-index: 9;
		position: absolute;
		right: 0;
		margin: 60px;
		margin-right: 60px;
		padding-bottom: 5px;
		color: #fff;
		border-bottom: 1px solid #fff;
		cursor: pointer;
		@include breakpoint(medium down) {
			display: none;
		}
	}
	.return {
		z-index: 9;
		position: absolute;
		left: 0;
   		margin: 22px;
   		display: none;
   		@include breakpoint(medium down) {
   			display: block;
   		}
	}
	.menuIntro {
		text-align: center;
		padding: 45px 120px;
		position: relative;
		@include breakpoint(medium down) {
			padding: 25px 59px;
    		padding-top: 45px;
    		&:after{
    			content: '';
    			position: absolute;
    			top: 12px;
			    left: 12px;
			    right: 12px;
			    bottom: 12px;
			    border: 1px solid #fff;
			    pointer-events: none;
    		}
		}
		.titleimg {
			font-family: $en-family;
			font-size: 30px;
			letter-spacing: 5px;
			color: #fff;
			padding: 30px;
			border-bottom: 1px solid #fff;
			position: relative;
			@include breakpoint(medium down) {
				padding: 0;
				padding-bottom: 21px;
			}
			img {
				@include breakpoint(medium down) {
					max-width: 280px;
	    			margin-left: -16px;
	    		}
			}
			&:after {
				content: "";
				width: 100%;
				height: 1%;
				background-color: #fff;
				position: absolute;
				bottom: 6px;
		    	left: 0;
			}
		}
		.menuList {
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    color: #fff;
			// height: 305px;
		    // padding-top: 170px;
		    height: 350px;
		    padding-top: 95px;
		    position: relative;
		    @include breakpoint(medium down) {
		    	padding-top: 0px;
		    	height: auto;
		    }
		    .topmenu-lanList{
		    	position: absolute;
		    	top: 46px;
	    	    left: 105px;
	    	    @include breakpoint(medium down){
	    	    	position: relative;
	    	    	top: 0;
	    	    	left: 0;
	    	    	width: 100%;
	    	    	margin: 30px auto -15px auto;
	    	    	@include flex-align(center, middle);
	    	    };
		    	a{
		    		font-family: $content-family;
		    		font-weight: 700;
		    		font-size: 22px;
		    		color: #fff;
		    		width: 76px;
		    		height: 46px;
		    		border: 2px solid #fff;
		    		border-radius: 200px;
		    		transition: all .5s;
		    		@include mr(25px);
		    		@include breakpoint(medium down){
		    			font-size: 16px;
		    			width: 60px;
		    			height: 37px;
		    			border: 2px solid #fff;
		    		};
		    		&:hover{
		    			color: #5d89b4;
		    			background-color: #fff;
		    		}
		    	}
		    }
		    .menuClass {
		    	li {
		    		font-weight: 700;
		    		margin: 50px auto;
		    		@include breakpoint(medium down) {
		    			font-size: 17px;
		    			margin: 48px auto;
		    		}
		    	}
		    }
		    .followus {
		    	position: relative;
		    	ul {
		    		position: absolute;
		    		left: 100px;
		    		top: -160px;
		    		@include breakpoint(medium down) {
		    			position: initial;
		    		}
		    	}
		    	.title {
			    	text-align: left;
			    	@include breakpoint(medium down) {
			    		padding-bottom: 10px;
			    	}
			    }
			    .follow-icon {
			    	border-top: 1px solid #fff;
			    	margin-top: 45px;
			    	@include breakpoint(medium down) {
			    		margin-top: 0px;
		    		}
			    	.follow-icon1 {
			    		text-align: left;
			    		li {
			    			display: inline-block;
			    			margin: 30px auto;
			    			margin-right: 60px;
			    			@include breakpoint(medium down) {
			    				margin: 15px auto;
			    				margin-right: 0px;
			    				width: 58px;
			    			}
			    		}
			    	}
			    	/* .follow-icon2 {*/
			    	/* 	text-align: left;*/
			    	/* 	@include breakpoint(medium down) {*/
			    	/* 		display: none;*/
			    	/* 	}*/
			    	/* 	li {*/
			    	/* 		display: inline-block;*/
			    	/* 		margin: 30px auto;*/
			    	/* 		margin-right: 60px;*/
			    	/* 	}*/
			    	/* }*/
			    }
		    }
		}
	}
	.menuContent {
		color: #fff;
	    font-size: 13px;
	    text-align: left;
	    padding-left: 77px;
	    padding-bottom: 4px;
		@include breakpoint(medium down) {
		    margin: 0 auto;
		    text-align: left;
		    padding-left: 0px;
		    line-height: 1.5;
		}
		.address {
			display: inline-block;
		    margin-right: 43px;
		    vertical-align: bottom;
		    margin-bottom: 20px;
		    font-size: 14px;
		    @include breakpoint(medium down) {
		    	margin-left: 0px;
    			margin-right: 0px;
    			margin-bottom: 5px;
		    }
		}
		.phone {
			display: inline-block;
		    margin-right: 43px;
		    vertical-align: bottom;
		    margin-bottom: 20px;
		    font-size: 14px;
		    @include breakpoint(medium down) {
		    	display: block;
		    	margin-bottom: 5px;
		    }
		}
		.phone2 {
			display: none;
			@include breakpoint(medium down) {
		    	display: block;
		    	margin-right: 45px;
			    vertical-align: bottom;
			    margin-bottom: 5px;
		    }
		}
		.mail {
			display: inline-block;
		    margin-right: 80px;
		    vertical-align: bottom;
		    margin-bottom: 20px;
		    font-size: 14px;
		    @include breakpoint(medium down) {
		    	display: block;
		    	margin-bottom: 5px;
		    }
		}
		.hotelimg {
			display: inline-block;
			img {
				vertical-align: unset;
			}
			@include breakpoint(medium down) {
				display: none;
			}
		}
	}
}
/*////////////======== index ========//////////////*/
.fix-bg {
	margin-top: 35px;
	height: 90vh;
	position: relative;
	.index-logo {
		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
		margin-top: -170px;
		margin-left: -189px;
		@include breakpoint(medium down) {
			position: relative;
			top: 25%;
			left: 0%;
   			margin: 0 auto;
    		text-align: center;
    		width: 80%;
		}
	}
	.bigbanner {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		clip: rect(0, auto, auto, 0);
		.bg {
			background-image: url(..//images/index-banner.jpg);
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}
	    .more {
	    	position: absolute;
		    left: 47%;
		    bottom: 1%;
		    cursor: pointer;
	    }
	}
}
#scrolldown {
	margin: 45px auto;
}
.left.introduction {
	margin: 55px auto;
	@include breakpoint(medium down) {
		margin: 65px auto;
    	max-width: 375px;
    	position: relative;
    	padding-bottom: 35px;
    	&:before {
    		content: "";
		    width: 50px;
		    height: 2px;
		    bottom: -20px;
		    background-color: #6786b8;
		    position: absolute;
		    left: 42%;
    	}
	}
	.ch {
		font-size: 45px;
    	line-height: 50px;
		color: $blue;
		font-family: $jk;
		@include breakpoint(medium down) {
			font-size: 33px;
		}
	}
	.en {
		font-size: 20px;
		margin: 10px auto;
		letter-spacing: 2px;
		color: $blue;
		margin-bottom: 20px;
		font-family: $bree-family;
		@include breakpoint(medium down) {
			font-size: 17px;
		}
	}
}
.right.introduction {
	margin: 55px auto;
	margin-left: 55px;
	@include breakpoint(medium down) {
		margin: auto;
		margin-left: 0px;
	}
	.ch-content {
		line-height: 28px;
    	letter-spacing: 3px;
    	font-size: 14px;
    	@include breakpoint(medium down) {
			display: none;
		}
	}
	.ch-content2 {
		display: none;
		line-height: 28px;
    	letter-spacing: 3px;
    	font-size: 16px;
		@include breakpoint(medium down) {
			display: block;
			border-bottom: 1px solid $blue;
			position: relative;
			padding-bottom: 20px;
			&:before {
				content: "";
				width: 100%;
				height: 1px;
				position: absolute;
				bottom: -6px;
				left: 0;
				background-color: $blue;
			}
		}
	}
}
.index-news {
	width: 100%;
	height: auto;
	background-color: $blue1;
	.title {
		width: 100%;
		text-align: center;
		margin-top: 85px;
		margin-bottom: 50px;
		@include breakpoint(medium down){
			margin-top: 50px;
			margin-bottom: 30px;
		};
		.en {
			color: #fff;
			font-size: 20px;
			margin-top: 30px;
			letter-spacing: 2px;
			font-family: $bree-family;
			@include breakpoint(medium down){
				margin-top: 10px;
			};
		}
	}
	.content {
		color: #fff;
		font-size: 15px;
		letter-spacing: 2px;
		line-height: 30px;
		margin-top: 60px;
		@include breakpoint(medium down) {
			display: none;
		}
	}
	.btn {
		border-radius: 30px;
	    line-height: 28px;
	    letter-spacing: 1px;
	    background: #fff;
	    width: 145px;
	    height: 28px;
	    display: block;
	    color: #6786b8;
	    text-decoration: none;
	    font-size: 16px;
	    margin: 50px auto;
	    font-family: $bree-family;
		@include breakpoint(medium down){
			margin: 40px auto 40px;
		};
	}
}
.index-findus {
	width: 100%;
	height: auto;
	background-color: #fff;
	.title {
		width: 100%;
		text-align: center;
		margin: 85px auto 25px;
		@include breakpoint(medium down){
			margin: 70px auto 40px;
		};
		.en {
			color: $blue;
			font-size: 20px;
			margin-top: 30px;
			letter-spacing: 2px;
			margin-left: 9px;
			font-family: $bree-family;
			@include breakpoint(medium down){
				margin-top: 10px;
			};
		}
	}
}
.homepage-caret {
	position: absolute;
	width: 78px;
	height: 30px;
	background-size: 48px 15px;
	bottom: 48px;
	left: 50%;
	margin-left: -34px;
	cursor: pointer;
	padding: 10px;
	box-sizing: content-box;
	z-index: 1;
	display: block;
	-webkit-transform: translateZ(0);
	        transform: translateZ(0);
	-webkit-animation: bounce 2s infinite;
	        animation: bounce 2s infinite;
	transition-property: bottom;
	transition-duration: 1000ms, 1000ms;
	transition-delay: 0, 1000ms;
	transition-timing-function: cubic-bezier(0, 0, 0, 1),linear;
	}
	@-webkit-keyframes bounce {
	0% {
	    -webkit-transform: translateY(0);
	            transform: translateY(0)
	}
	15% {
	    -webkit-transform: translateY(-10px);
	            transform: translateY(-10px)
	}
	30% {
	    -webkit-transform: translateY(0);
	            transform: translateY(0)
	}
	100% {
	    -webkit-transform: translateY(0);
	            transform: translateY(0)
	}
	}
	@keyframes bounce {
	0% {
	    -webkit-transform: translateY(0);
	            transform: translateY(0)
	}
	15% {
	    -webkit-transform: translateY(-10px);
	            transform: translateY(-10px)
	}
	30% {
	    -webkit-transform: translateY(0);
	            transform: translateY(0)
	}
	100% {
	    -webkit-transform: translateY(0);
	            transform: translateY(0)
	}
	}
/*////////////======== news ========//////////////*/
.news {
	width: 100%;
	height: auto;
	background-color: #fff;
	.title {
	width: 100%;
	text-align: center;
	margin: 55px 0 25px;
	@include breakpoint(medium down) {
		margin: 0 0 15px;
	}
		.en {
			color: $blue;
			font-size: 20px;
			margin-top: 15px;
			letter-spacing: 2px;
			font-family: $bree-family;
		}
	}
}
.newsList {
	.newsClass {
		margin-bottom: 60px;
		// text-align: center;
		@include breakpoint(medium down) {
			margin-bottom: 35px;
			// text-align: left;
		}
	}
	.newsNav {
		display: inline-block;
	    margin-right: 80px;
	    margin-left: 25px;
	    margin-bottom: 40px;
	    border-radius: 28px;
	    line-height: 28px;
	    letter-spacing: 2px;
	    background: $blue1;
	    width: 110px;
	    height: 28px;
	    color: #fff;
	    text-decoration: none;
	    font-size: 15px;
	    font-weight: 700;
	    text-align: center;
	    &:last-child {
	    	margin-right: 20px;
	    }
	    @include breakpoint(medium down) {
	    	margin-right: 0;
    	    margin-top: 22px;
    	    margin-left: 25px;
	    }
	}
	li.topLine {
    	border-top: 1px solid $blue1;
    	@include breakpoint(medium down) {
    		border-top: none;
			&:last-child {
				border-bottom: 1px solid $blue;
			}
    	}
	}
	.newsList-content {
		margin: 50px auto;
	}
	.pic {
	    margin-right: 45px;
	    margin-left: 25px;
	    display: inline-block;
    	vertical-align: top;
    	@include breakpoint(medium down) {
    		margin-right: 0px;
	    	margin-left: 0px;
    	}
	}
	.newsIn {
		display: inline-block;
		width: 372px;
    	margin-left: 70px;
    	margin-top: 28px;
    	letter-spacing: 2px;
    	@include breakpoint(medium down) {
    		width: 100%;
    		margin-left: 0px;
    	}
	}
	.date {
		font-size: 16px;
		font-weight: 700;
    	margin-bottom: 10px;
	}
	.title {
		font-size: 17px;
	    margin-bottom: 20px;
	    line-height: 1.5;
	}
	.content {
		font-size: 14px;
		margin-right: 5px;
	    margin-bottom: 22px;
	    line-height: 27px;
	}
	.go {
		font-size: 16px;
    	color: #336699;
    	font-weight: 700;
    	@include breakpoint(medium down) {
    		display: none;
    	}
	}
	.go-en {
		display: none;
		@include breakpoint(medium down) {
			display: block;
		    border-radius: 12px;
		    line-height: 26px;
		    letter-spacing: 1px;
		    background: #ffffff;
		    width: 106px;
		    height: 28px;
		    color: #6786b8;
		    text-decoration: none;
		    font-size: 14px;
		    text-align: center;
		    border: 1px solid #6786b8;
		    font-weight: 700;
		}
	}
}
.news-detail {
	width: 1024px;
	padding: 70px 80px 120px;
	@include breakpoint(medium down) {
		width: 100%;
		padding: 70px 30px;
	}
	.date {
	}
	.title {
		font-size: 22px;
		line-height: 1.5;
		margin-top: 20px;
    	margin-bottom: 50px;
	}
	.content {
		font-size: 14px;
		line-height: 24px;
	}
}
.newdetailWave {
	background-image: url(..//svg/wave.svg);
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-size: 100% 750px;
    margin: 50px auto;
    padding-top: 130px;
    @include breakpoint(medium down){
    	background-position: 70% 0%;
    	background-size: 200% 380px;
    	margin: 0 auto 50px;
    	padding: 90px 10px 0;
    };
    img{
    	margin-bottom: 55px;
    	@include breakpoint(medium down){
    		margin-bottom: 12px;
    	};
    }
}
.newdetailReturn {
	position: fixed;
	right: 22px;
    bottom: 90px;
}
/*////////////======== rooms ========//////////////*/
.pc-roomsClass {
	display: block;
	margin-bottom: 120px;
	@include breakpoint(medium down) {
		display: none;
	}
}
.roomsClass {
	text-align: center;
	border-bottom: 1px solid $blue-deep;
	padding-bottom: 70px;
	position: relative;
	padding-top: 40px;
	&:after {
		content: "";
	    position: absolute;
	    bottom: -7px;
	    width: 100%;
	    background-color: $blue-deep;
	    height: 1px;
	}
	.hey{
		margin-top: 50px;
	}
	.pic{
		margin-bottom: 27px;
		display: inline-block;
		border-radius: 50%;
		position: relative;
		&:before{
			content: '';
			position: absolute;
			top: -7px;
			right: -7px;
			bottom: -7px;
			left: -7px;
			border: 1px solid $blue-deep;
			border-radius: 50%;
			opacity: 0;
		}
		&:after{
			content: '';
			position: absolute;
			top: -13px;
			right: -13px;
			bottom: -13px;
			left: -13px;
			border: 2px solid $blue-deep;
			border-radius: 50%;
			opacity: 0;
		}
	}
	.roomsClassContent {
		display: inline-block;
		padding: 0 12px;
		.ch {
			font-size: 18px;
			color: $blue-deep;
			letter-spacing: 2px;
			margin-bottom: 12px;
			@include breakpoint(medium down){
				font-size: 16px;
				margin-bottom: 8px;
			};
		}
		.en {
			font-family: $en-family;
			font-size: 14px;
			color: $blue-deep;
			letter-spacing: 1px;
			@include breakpoint(medium down){
				font-size: 13px;
			};
		}
	}
	.current {
		.pic{
			&:before{opacity: 1;}
			&:after{opacity: 1;}
		}
		.roomsClassContent {
			border-Left: 1px solid $blue-deep;
			border-right: 1px solid $blue-deep;
			position: relative;
			margin-top: 3px;
			&:before {
				content: "";
			    position: absolute;
			    width: 2px;
			    height: 100%;
			    background-color: $blue-deep;
			    left: -5px;
			}
			&:after {
				content: "";
			    position: absolute;
			    width: 2px;
			    height: 100%;
			    background-color: $blue-deep;
			    right: -5px;
			    top: 0;
			}
		}
	}
}
.rooms {
	width: 100%;
	height: auto;
	background-color: #fff;
	margin-bottom: 100px;
	.title {
	width: 100%;
	text-align: center;
	margin: 70px auto;
	@include breakpoint(medium down) {
		display: none;
	}
	img{
		display: inline-block;
	}
		.en {
			color: $blue;
			font-size: 19px;
		    margin-top: 10px;
		    margin-bottom: 20px;
		    letter-spacing: 1px;
			font-family: $bree-family;
		}
		.ch {
			color: $blue;
			letter-spacing: 4px;
			font-size: 17px;
		}
	}
	.image {
			@include breakpoint(medium down) {
				display: none;
			}
		}
}
.mobile-rooms {
	width: 100%;
	height: auto;
	background-color: #fff;
	margin-bottom: 20px;
	display: none;
	@include breakpoint(medium down) {
		display: block;
	}
	.title {
	width: 100%;
	text-align: center;
	margin: 70px auto;
	@include breakpoint(medium down) {
		margin: 20px auto;
	}
	img{
		display: inline-block;
	}
		.en {
			color: $blue;
			font-size: 19px;
		    margin-top: 10px;
		    margin-bottom: 20px;
		    letter-spacing: 1px;
			font-family: $bree-family;
		}
		.ch {
			color: $blue;
			letter-spacing: 4px;
		}
	}
	.image {
			@include breakpoint(medium down) {
				display: none;
			}
		}
}
.roomsIntroduce {
	margin: 65px auto;
	border-top: 1px solid $blue-deep;
	position: relative;
	@include breakpoint(medium down) {
		border-top: none;
		margin: 30px auto;
		margin-bottom: 0;
	}
	&:before {
		content: "";
	    width: 100%;
	    height: 1px;
	    background-color: $blue-deep;
	    position: absolute;
	    top: 5px;
	    @include breakpoint(medium down) {
	    	top: 108px;
	    }
	}
	ul.roomsPerson {
		padding: 50px 45px;
		border-bottom: 1px solid $blue-deep;
		font-family: $jk;
		@include breakpoint(medium down) {
			padding: 0px 10px;
		}
		li {
			display: inline-block;
			font-size: 20px;
			letter-spacing: 2px;
			margin-right: 110px;
			color: $blue-deep;
			@include breakpoint(medium down) {
				margin-right: 0px;
				font-size: 15px;
				margin-bottom: 20px;
			}
			img{
				display: inline-block;
				vertical-align: middle;
			}
			.price {
				display: inline-block;
				span {
					margin: 0 12px;
				}
				span.price {
					color: #666666;
				}
			}
			.people {
				display: inline-block;
				span {
					margin: 0 12px;
				}
				span.person {
					color: #666666;
				}
			}
		}
		img {
			margin-right: 10px;
			@include breakpoint(medium down) {
				width: 16px;
			}
		}
	}
	ul.roomsIcon {
		padding: 50px 45px 25px;
		border-bottom: 1px solid $blue-deep;
		@include breakpoint(medium down) {
			padding: 25px 10px;
    		padding-bottom: 0px;
		}
		li {
			display: inline-block;
			margin-right: 22px;
			margin-bottom: 25px;
		    vertical-align: top;
		    text-align: center;
		    width: 80px;
		    @include breakpoint(medium down) {
		    	width: 76px;
		    	margin-bottom: 20px;
		    }
		    .icon{
		    	margin-bottom: 15px;
		    	height: 33px;
		    	line-height: 33px;
		    	>img{
		    		max-height: 100%;
		    	}
		    }
		    .iconname{
		    	font-family: $content-family;
		    	font-size: 13px;
		    	line-height: 1.3;
		    	color: #000;
		    }
		}
	}
}
.rooms2 {
	padding: 10px 70px;
	position: relative;
	margin-bottom: 20px;
	@include breakpoint(medium down) {
		padding: 10px 10px;
	}
	img {
		display: inline-block;
		margin-right: 30px;
	}
	.roomsb {
		display: inline-block;
		vertical-align: bottom;
		font-family: $jk;
	}
	.roomsbb {
		display: inline-block;
		vertical-align: middle;
		font-family: $jk;
	}
	.roomsb-content {
		font-size: 15px;
	    letter-spacing: 2px;
	    line-height: 32px;
	    margin-top: 25px;
	}
}
.roomsContent1 {
	width: 100%;
	font-size: 20px;
	color: $blue-deep;
	margin-top: 30px;
}
.roomsContent2 {
	width: 100%;
	background-color: $blue1;
	color: #fff;
	font-size: 16px;
	line-height: 2;
    padding: 55px 0;
    letter-spacing: 2px;
    display: none;
    @include breakpoint(medium down){
    	letter-spacing: 2px;
    	padding: 25px 0 7px;
    };
	ul {
		list-style: initial;
	}
	b, strong{
		font-weight: 700;
	}
}
.roomsContent3 {
	width: 100%;
	font-size: 20px;
	color: $blue-deep;
	margin: 35px 0;
}
.btn2 {
	border-radius: 28px;
    line-height: 38px;
    letter-spacing: 2px;
    background: $blue-deep;
    width: 150px;
    height: 38px;
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 22px;
    padding-left: 28px;
    margin: 50px auto 0px;
}
.plus {
	display: inline-block;
	vertical-align: bottom;
    top: 65%;
    transform: translate(15%, 15%);
    -webkit-transform: translate(15%, 15%);
    width: 24px;
    height: 24px;
    border: 2px solid #5d89b4;
    border-radius: 35%;
    left: 26%;
    cursor: pointer;
	&:before, &:after{
		content: '';
		width: 10px;
		height: 2px;
		background-color: #5d89b4;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(0deg);
		-webkit-transform: translate(-50%, -50%) rotate(0deg);
		transition: all 0.4s;
		-webkit-transition: all 0.4s;
	}
	&:after{
		width: 2px;
    	height: 10px;
	}
}
.plus-is-open{
	&:after{
		transform: translate(-50%,-50%) rotate(270deg);
		-webkit-transform: translate(-50%,-50%) rotate(270deg);
	}
}
.plus2 {
	display: inline-block;
	vertical-align: bottom;
    top: 65%;
    transform: translate(15%, 15%);
    -webkit-transform: translate(15%, 15%);
    width: 24px;
    height: 24px;
    border: 2px solid #5d89b4;
    border-radius: 35%;
    left: 26%;
    cursor: pointer;
	&:before, &:after{
		content: '';
		width: 10px;
		height: 2px;
		background-color: #5d89b4;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(0deg);
		-webkit-transform: translate(-50%, -50%) rotate(0deg);
		transition: all 0.4s;
		-webkit-transition: all 0.4s;
	}
}
.plus2-is-open{
	&:after{
		transform: translate(-50%,-50%) rotate(270deg);
		-webkit-transform: translate(-50%,-50%) rotate(270deg);
	}
}
.mobile-roomsClass {
	display: none;
	@include breakpoint(medium down) {
		display: block;
	}
	.roomsClass {
		@include breakpoint(medium down) {
			border-top: 1px solid $blue1;
			margin-bottom: 60px;
		}
		.hey{
			padding: 0 10px;
		}
	}
}
.mobile-roomsTopNav {
	display: none;
	margin-bottom: 33px;
	.nice-select{
			float: initial;
			width: 170px;
    		height: 34px;
			margin: 0 auto;
			border: 1px solid $blue-deep;
			font-size: 15px;
			letter-spacing: 4px;
			color: #fff;
			border-radius: 35px;
		    background-color: $blue-deep;
		    line-height: 32px;
			.list{
				width: 100%;
				border: 2px solid $blue-deep;
				background-color: $blue-deep;
				border-radius: 20px;
			}
			&:after{
				border-color: #fff;
			}
			.option.focus{background-color: inherit;}
		}
	@include breakpoint(medium down) {
		display: block;
	}
}
/*////////////======== amenities ========//////////////*/
.amenities {
	display: block;
	width: 100%;
	height: auto;
	background-color: #fff;
	.title {
	width: 100%;
	text-align: center;
	margin: 70px auto;
	@include breakpoint(medium down) {
		margin-top: 10px auto;
	}
		.en {
			color: $blue;
			font-size: 17px;
			margin-top: 10px;
			margin-bottom: 20px;
			letter-spacing: 1px;
			font-family: $bree-family;
		}
	}
	@include breakpoint(medium down) {
		display: none;
	}
}
.mobile-amenities {
	display: none;
	width: 100%;
	height: auto;
	background-color: #fff;
	.title {
		width: 100%;
		text-align: center;
		margin: 70px auto;
		@include breakpoint(medium down){
			margin-bottom: 0;
		};
		.en {
			color: $blue;
			font-size: 17px;
			margin-top: 10px;
			margin-bottom: 20px;
			letter-spacing: 1px;
			font-family: $bree-family;
		}
	}
	@include breakpoint(medium down) {
		display: block;
	}
}
.amenitiesClass {
	text-align: center;
	font-family: $dft_r5;
	ul {
		.nav {
			display: inline-block;
		    margin-right: 60px;
		    margin-left: 52px;
		    margin-bottom: 95px;
		    border-radius: 28px;
		    line-height: 26px;
		    letter-spacing: 2px;
		    background: $blue1;
		    width: 106px;
		    height: 28px;
		    color: #fff;
		    text-decoration: none;
		    font-size: 20px;
		    text-align: center;
		    @include breakpoint(medium down) {
		    	margin-right: 0px;
    			margin-left: 0px;
			    margin-bottom: 45px;
			    width: 105px;
			    font-size: 16px;
		    }
		}
	}
}
.bgblue {
	background-color: $blue;
}
.bgwhite {
	background-color: #fff;
}
.floor-1 {
	padding-top: 55px;
	padding-bottom: 60px;
	position: relative;
	background-color: #5d89b4;
	color: #fff;
	width: 1280px;
	margin: 0 auto;
	@include breakpoint(medium down) {
		text-align: center;
	}
	.f-1 {
		position: relative;
		font-size: 50px;
		left: 17%;
		letter-spacing: 5px;
		font-family: $bree-family;
		@include breakpoint(medium down) {
			left: auto;
			margin: auto;
		}
		&:after {
			content: "";
			position: absolute;
			top: 75px;
			left: -15px;
			width: 95px;
			height: 1px;
			background-color: #fff;
		}
	}
	ul {
		line-height: 30px;
		padding-left: 215px;
		letter-spacing: 2px;
		font-size: 14px;
		position: relative;
		li {
			&:before {
				content: "•";
				margin-right: 5px;
			}
		}
		@include breakpoint(medium down) {
			padding-left: 0px;
    		padding: 0 75px;
    		margin: 80px auto 20px;
		}
		.note {
			position: absolute;
			left: 216px;
			@include breakpoint(medium down) {
				position: relative;
				left: auto;
			}
		}
	}
	.en {
		padding-top: 9px;
		border-top: 1px solid #fff;
	}
}
.floor-2 {
	padding-top: 55px;
	position: relative;
	background-color: #fff;
	color: $blue;
	padding-bottom: 90px;
	width: 1280px;
	margin: 0 auto;
	@include breakpoint(medium down) {
		text-align: center;
	}
	.f-2 {
		position: relative;
		font-size: 50px;
		left: 20%;
		letter-spacing: 5px;
		font-family: $bree-family;
		@include breakpoint(medium down) {
			left: auto;
			margin: auto;
		}
		&:after {
			content: "";
			position: absolute;
			top: 75px;
			left: 75px;
			width: 95px;
			height: 1px;
			background-color: $blue;
			@include breakpoint(medium down) {
				left: 5px;
			}
		}
		@include breakpoint(medium down) {
			left: auto;
		}
	}
	ul {
		line-height: 30px;
		letter-spacing: 2px;
		font-size: 14px;
		padding-right: 105px;
		color: #000;
		position: relative;
		li {
			&:before {
				content: "•";
				margin-right: 5px;
			}
		}
		@include breakpoint(medium down) {
			padding-left: 0px;
    		padding: 0 75px;
    		margin: 80px auto 20px;
		}
		.note {
			position: absolute;
			left: 2px;
			@include breakpoint(medium down) {
				position: relative;
				left: auto;
			}
		}
	}
	.en {
		padding-top: 9px;
		border-top: 1px solid $blue;
	}
}
.floor-3 {
	padding-top: 55px;
	padding-bottom: 100px;
	position: relative;
	background-color: #5d89b4;
	color: #fff;
	width: 1280px;
    margin: auto;
	@include breakpoint(medium down) {
		width: auto;
		text-align: center;
		padding-bottom: 50px;
	}
	.f-3 {
		position: relative;
		font-size: 50px;
		left: 10%;
		letter-spacing: 5px;
		font-family: $bree-family;
		@include breakpoint(medium down) {
			left: -5px;
			margin: auto;
		}
		&:after {
			content: "";
		    position: absolute;
		    top: 75px;
		    left: 0px;
		    width: 95px;
		    height: 1px;
		    background-color: #fff;
		}
	}
	ul {
		line-height: 30px;
		padding-right: 270px;
		letter-spacing: 2px;
		font-size: 14px;
		position: relative;
		li {
			&:before {
				content: "•";
				margin-right: 5px;
			}
		}
		@include breakpoint(medium down) {
			padding-left: 0px;
    		padding: 0 75px;
    		margin: 80px auto 20px;
		}
		.note {
			position: absolute;
			left: 90px;
			@include breakpoint(medium down) {
				position: relative;
				left: auto;
			}
		}
	}
	.en {
		padding-top: 9px;
		border-top: 1px solid #fff;
	}
}
.amenities-img {
	padding-top: 80px;
	position: relative;
	@include breakpoint(medium down) {
		padding-top: 40px;
	}
	.amenities-area-1 {
		position: absolute;
		max-width: 120px;
		text-align: center;
		&:nth-child(even) {
			bottom: 0;
			right: 12%;
			@include breakpoint(medium down) {
				bottom: auto;
		    	right: auto;
		    	position: relative;
		    	margin: 0 auto;
		    	margin-top: 30px;
			}
		}
	}
	.amenities-area-1_2 {
		position: absolute;
		max-width: 120px;
		text-align: center;
		&:nth-child(even) {
			bottom: 0;
			left: 12%;
			@include breakpoint(medium down) {
				bottom: auto;
		    	left: auto;
		    	position: relative;
		    	margin: 0 auto;
		    	margin-top: 30px;
			}
		}
	}
	.ch {
		letter-spacing: 2px;font-size: 18px;padding: 10px 0px;
	}
}
/*////////////======== attractions ========//////////////*/
.attractions {
	width: 100%;
	height: auto;
	background-color: #fff;
	.title {
	width: 100%;
	text-align: center;
	margin-top: 150px;
	@include breakpoint(medium down) {
		margin-top: 100px;
    	margin-bottom: 45px;
	}
		.en {
			color: $blue;
			font-size: 17px;
			margin-top: 10px;
			margin-bottom: 20px;
			letter-spacing: 1px;
			font-family: $bree-family;
		}
	}
}
#attractionsAnchor{
	position: relative;
	top: -80px;
}
#newsAnchor{
	position: relative;
	top: -80px;
}
.attractionsClass {
	text-align: center;
	ul {
		.navClass {
			display: inline-block;
		    margin: 0 33px;
		    position: relative;
		    text-align: center;
		    @include breakpoint(medium down) {
		    	vertical-align: bottom;
		    	margin: 0 16px;
		    }
		    .aaaaaaaaaaatitle {
    			color: $blue1;
    			font-weight: 700;
    			line-height: 10px;
    			opacity: 0;
    			position: absolute;
    			top: -30px;
    			left: 50%;
    			transform: translateX(-50%);
    			white-space: nowrap;
    			&.current{
    				opacity: 1;
    			}
				span {
	    			width: 50%;
				    height: 1px;
				    background-color: #5d89b4;
				    display: inline-block;
				    position: absolute;
				    left: 25%;
				    bottom: -8px;
				}
			}
		}
	}
}
.attractionsList {
	.attractionsClass {
		margin-bottom: 100px;
		@include breakpoint(medium down) {
			margin-bottom: 35px;
			margin-top: 40px;
		}
	}
	.attractionsNav {
		display: inline-block;
	    margin-right: 80px;
	    margin-left: 25px;
	    border-radius: 28px;
	    line-height: 35px;
	    letter-spacing: 2px;
	    background: #6786b8;
	    width: 120px;
	    height: 36px;
	    color: #fff;
	    text-decoration: none;
	    font-size: 16px;
	    padding-left: 25px;
	    &:last-child {
	    	margin-right: 20px;
	    }
	}
	ul {
		position: relative;
		li.topLine {
	    	border-top: none;
	    	border-top: 1px solid $blue1;
	    	width: 1100px;
	    	@include breakpoint(medium down) {
	    		width: 100%;
	    	}
	    	&:first-child {
	    		&:before {
	    			content: "";
	    			width: 100%;
	    			height: 1px;
	    			background-color: $blue1;
	    			position: absolute;
	    			top: -7px;
	    		}
	    	}
		}
	}
	.attractionsList-content {
		margin: 50px auto;
	}
	.pic {
	    display: inline-block;
    	vertical-align: top;
    	margin: auto 95px;
    	@include breakpoint(medium down) {
    		margin: auto;
    	}
	}
	.pic-area{
		width: 500px;
		@include breakpoint(medium down){
			width: 100%;
			margin-bottom: 30px;
		};
	}
	.attractionsIn {
		display: inline-block;
		max-width: 372px;
    	margin-left: 70px;
    	margin-top: 55px;
    	letter-spacing: 2px;
    	text-align: center;
    	color: $blue;
	}
	.article-area{
		letter-spacing: 2px;
		text-align: center;
		color: $blue;
	}
	.title {
		font-size: 19px;
	    margin-bottom: 10px;
	    line-height: 1.5;
	    font-weight: 700;
	}
	.content {
		font-size: 14px;
		margin-right: 5px;
	    margin-bottom: 22px;
	    line-height: 27px;
	    display: inline-block;
	    border-top: 1px solid $blue1;
    	padding-top: 10px;
    	letter-spacing: 0px;
	}
	.nav {
	    a{
			display: inline-block;
		    border-radius: 10px;
		    line-height: 24px;
		    letter-spacing: 1px;
		    background: #fff;
		    width: 105px;
		    height: 24px;
		    color: #6786b8;
		    text-decoration: none;
		    font-size: 14px;
		    border: 1px solid #6786b8;
	    }
	}
}
/*////////////======== attractions_detail ========//////////////*/
.attractions-title {
	text-align: center;
    margin: 75px auto;
    max-width: 270px;
    @include breakpoint(medium down){
    	margin: 45px auto 30px;
    };
    .title {
    	border-bottom: 1px solid $blue1;
    	padding-bottom: 17px;
    	.ch {
			display: inline-block;
    		margin-left: 15px;
   			font-size: 20px;
   			color: $blue;
   			font-weight: 700;
   			vertical-align: bottom;
    	}
    }
    .en {
	    line-height: 30px;
	    padding-top: 10px;
	    letter-spacing: 1px;
	    font-size: 14px;
	    color: $blue1;
	    text-align: center;
    }
}
.attractions-content {
    margin: 0px 100px 50px;
    position: relative;
    letter-spacing: 2px;
    font-size: 14px;
    line-height: 23px;
    @include breakpoint(medium down) {
    	margin: 0px 30px 0;
    	// text-align: center;
    }
	.aaaaaaaacontent-one{
		margin-bottom: 80px;
	}
	.aaaaaaaacontent-two{
		margin-bottom: 80px;
		.ryder-span{
			color: #5d88b3;
		    font-weight: 700;
		    display: block;
		    margin-bottom: 15px;
		}
	}
	.aaaaaaaacontent-three{
		margin-bottom: 80px;
		.ryder-span{
			color: #5d88b3;
		    font-weight: 700;
		    display: block;
		    margin-bottom: 15px;
		}
	}
}
.back {
	margin: 50px auto;
}
/*////////////======== location ========//////////////*/
.location {
	width: 100%;
	height: auto;
	background-color: #fff;
	.title {
	width: 100%;
	text-align: center;
	margin-top: 150px;
		.en {
			color: $blue;
			font-size: 17px;
			margin-top: 10px;
			margin-bottom: 20px;
			letter-spacing: 1px;
			font-family: $bree-family;
		}
	}
}
.locationClass {
	text-align: left;
	margin-top: 70px;
	.navClass {
		display: inline-block;
		text-align: center;
	    border-radius: 28px;
	    line-height: 27px;
	    letter-spacing: 2px;
	    background: $blue1;
	    // width: 105px;
	    // height: 27px;
	    color: #fff;
	    text-decoration: none;
	    font-size: 15px;
	    font-weight: 700;
	    padding: 1px 18px;
	    width: 174px;
	    margin: 0 20px 40px;
	    @include breakpoint(medium down) {
	    	width: auto;
	    	margin: 0 14px 20px 0;
	    }
	    &:last-child {
	    	margin-right: 20px;
	    }
	}
}
.locationList {
	// background-image: url(..//svg/wave.svg);
 //    background-repeat: no-repeat;
 //    background-position: 0% 5%;
 //    background-size: 100% 755px;
 	background-color: #5d89b5;
    margin-top: 50px;
    padding-top: 10px;
    @include breakpoint(medium down) {
    	background: url(../images/ddddddd.svg) 0 0/100% calc(100% - 55px) no-repeat;
    	padding-top: 0;
    }
	.locationClass {
		margin-bottom: 100px;
	}
	.locationNav {
		display: inline-block;
	    margin-right: 80px;
	    margin-left: 25px;
	    border-radius: 28px;
	    line-height: 35px;
	    letter-spacing: 2px;
	    background: #6786b8;
	    width: 120px;
	    height: 36px;
	    color: #fff;
	    text-decoration: none;
	    font-size: 16px;
	    padding-left: 25px;
	    &:last-child {
	    	margin-right: 20px;
	    }
	}
	.information {
		margin: 50px auto;
	    padding-bottom: 50px;
	    &:first-child {
	    	padding-top: 75px;
	    	border-bottom: 1px solid #fff;
	    	@include breakpoint(medium down){
	    		padding-top: 25px;
	    	};
	    }
	    .pic {
	    	margin-left: 60px;
	    	@include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
	    }
	    .position {
	    	text-align: center;
		    color: #fff;
		    margin-left: 45px;
		    @include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
		    .title {
				margin-top: 35px;
		    }
		    .ch {
				font-size: 22px;
			    font-weight: 700;
			    padding-bottom: 15px;
		    }
		    .en {
			    display: inline-block;
			    border-top: 1px solid #fff;
			    padding-top: 7px;
			    text-align: left;
			    font-size: 14px;
			    letter-spacing: 1px;
			    line-height: 25px;
			    margin-bottom: 10px;
		    }
		    .btn {
		    	display: inline-block;
		    	border-radius: 11px;
			    line-height: 23px;
			    letter-spacing: 2px;
			    background: #5d89b4;
			    width: 100px;
			    height: 24px;
			    display: block;
			    color: #fff;
			    font-size: 14px;
			    margin: 10px auto;
			    border: 1px solid #fff;
		    }
		    .content {
		    	font-size: 14px;
			    letter-spacing: 2px;
			    line-height: 25px;
			    display: inline-block;
			    text-align: left;
			    margin-top: 7px;
		    }
	    }
	}
}
.locatio-taoyuan {
	.information-2 {
	    .pic {
	    	margin-left: 60px;
	    	@include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
	    }
	    .position {
	    	text-align: center;
		    color: $blue1;
		    margin-left: 45px;
		    @include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
		    .title {
				margin-top: 35px;
		    }
		    .ch {
				font-size: 22px;
			    font-weight: 700;
			    padding-bottom: 15px;
		    }
		    .en {
			    display: inline-block;
			    border-top: 1px solid $blue1;
			    padding: 7px 0;
			    text-align: left;
			    font-size: 14px;
			    letter-spacing: 1px;
			    margin-bottom: 13px;
			    line-height: 25px;
		    }
		    .content {
		    	font-size: 14px;
			    letter-spacing: 2px;
			    line-height: 25px;
			    display: inline-block;
			    text-align: left;
		    }
	    }
	}
	.taoyuan-airport {
		padding-left: 40px;
		padding-top: 85px;
		margin: 85px auto;
		border-top: 1px solid $blue;
		@include breakpoint(medium down) {
			padding-left: 0px;
			padding-top: 0px;
			margin: 0 auto;
			text-align: center;
			padding: 30px 0;
			border-top: none;
			border-bottom: 1px solid $blue;
		}
		&:first-child {
			margin-top: 0px;
		}
		.title {
			text-align: center;
   			max-width: 140px;
   			@include breakpoint(medium down) {
   				max-width: 100%;
   			}
			.ch {
				color: $blue;
				font-size: 22px;
				font-weight: 700;
				margin-bottom: 17px;
			}
			.en {
				color: $blue;
				font-size: 15px;
    			font-weight: 700;
    			margin-bottom: 17px;
			}
			.btn {
				color: $blue1;
				border: 1px solid $blue1;
		    	border-radius: 11px;
			    line-height: 23px;
			    letter-spacing: 0px;
			    background-color: #fff;
			    width: 92px;
			    height: 24px;
			    display: inline-block;
			    font-size: 14px;
			    @include breakpoint(medium down){
			    	margin-bottom: 20px;
			    };
			}
		}
		.content {
			line-height: 27px;
			font-size: 14px;
		}
		.price {
			line-height: 27px;
			font-size: 14px;
			padding-left: 45px;
		}
	}
}
.locatio-songshan {
	background-color: $blue1;
	.information-2 {
		padding-top: 55px;
	    .pic {
	    	margin-left: 25px;
	    	@include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
	    }
	    .position {
		    color: #fff;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    text-align: center;
		    .title {
				margin-top: 35px;
		    }
		    .ch {
				font-size: 22px;
			    font-weight: 700;
			    padding-bottom: 15px;
		    }
		    .en {
			    display: inline-block;
			    border-top: 1px solid #fff;
			    padding: 7px 0;
			    text-align: left;
			    font-size: 14px;
			    letter-spacing: 1px;
			    margin-bottom: 13px;
			    line-height: 25px;
		    }
		    .content {
		    	font-size: 14px;
			    letter-spacing: 2px;
			    line-height: 25px;
			    display: inline-block;
			    text-align: left;
		    }
	    }
	}
	.songshan-airport {
		padding-left: 40px;
		padding-top: 85px;
		margin: 85px auto;
		border-top: 1px solid #fff;
		@include breakpoint(medium down) {
			padding-left: 0px;
			padding-top: 0px;
			margin: 0 auto;
			padding: 35px 0;
			border-top: none;
			border-bottom: 1px solid #fff;
			text-align: center;
		}
		&:first-child {
			margin-top: 0px;
		}
		&:last-child {
			padding-bottom: 125px;
			position: relative;
			margin-bottom: 0;
	    		&:before {
	    			content: "";
				    width: 100%;
				    height: 1px;
				    background-color: #fff;
				    position: absolute;
				    bottom: 45px;
				    left: 0;
	    		}
		}
		.title {
			text-align: center;
   			max-width: 140px;
   			@include breakpoint(medium down) {
   				max-width: 100%;
   			}
			.ch {
				color: #fff;
				font-size: 22px;
				font-weight: 700;
				margin-bottom: 17px;
			}
			.en {
				color: #fff;
				font-size: 15px;
    			font-weight: 700;
    			margin-bottom: 17px;
			}
			.btn {
				color: #fff;
				border: 1px solid #fff;
		    	border-radius: 11px;
			    line-height: 23px;
			    letter-spacing: 0px;
			    background-color: $blue1;
			    width: 92px;
			    height: 24px;
			    display: inline-block;
			    font-size: 14px;
				@include breakpoint(medium down){
					margin-bottom: 20px;
				};
			}
		}
		.content {
			line-height: 27px;
			color: #fff;
			font-size: 14px;
		}
		.price {
			line-height: 27px;
			color: #fff;
			font-size: 14px;
			padding-left: 45px;
		}
	}
}
.locatio-parking {
	.information-2 {
		padding-top: 55px;
	    .pic {
	    	margin-left: 25px;
	    	@include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
	    }
	    .position {
		    color: $blue;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    text-align: center;
		    .title {
				margin-top: 35px;
		    }
		    .ch {
				font-size: 22px;
			    font-weight: 700;
			    padding-bottom: 15px;
		    }
		    .en {
			    display: inline-block;
			    border-top: 1px solid $blue;
			    padding: 7px 0;
			    text-align: left;
			    font-size: 14px;
			    letter-spacing: 1px;
			    margin-bottom: 13px;
			    line-height: 25px;
		    }
		    .content {
		    	font-size: 14px;
			    letter-spacing: 2px;
			    line-height: 25px;
			    display: inline-block;
			    text-align: left;
		    }
	    }
	}
	.parking {
		padding-left: 40px;
		padding-top: 85px;
		margin: 85px auto;
		border-top: 1px solid $blue;
		@include breakpoint(medium down) {
			padding-left: 0px;
			padding-top: 0px;
			margin: 0 auto;
			border-top: none;
			border-bottom: 1px solid $blue;
			padding: 35px 0;
			text-align: center;
		}
		&:first-child {
			margin-top: 0px;
		}
		&:last-child {
			padding-bottom: 90px;
    		position: relative;
    		margin-bottom: 60px;
			border-bottom: 1px solid $blue;
	    		&:before {
	    			content: "";
				    width: 100%;
				    height: 1px;
				    background-color: $blue;
				    position: absolute;
				    bottom: 7px;
				    left: 0;
	    		}
		}
		.title {
			width: 100%;
			padding-left: 20px;
			.ch {
				color: $blue;
				font-size: 20px;
				font-weight: 700;
				margin-bottom: 17px;
				line-height: 27px;
			}
		}
		.content {
			line-height: 27px;
			color: #000;
			font-size: 14px;
		}
		.price {
			line-height: 27px;
			color: #000;
			font-size: 14px;
			padding-left: 45px;
		}
	}
}
/*////////////======== Q & A ========//////////////*/
.qa {
	margin-bottom: 60px;
	.title {
		text-align: center;
	}
}
.qaList {
	.qaClass {
		margin-bottom: 100px;
		@include breakpoint(medium down){
			margin-bottom: 40px;
		};
	}
	.qaNav {
		display: inline-block;
	    margin-right: 80px;
	    margin-left: 25px;
	    border-radius: 28px;
	    line-height: 28px;
	    letter-spacing: 2px;
	    background: #6786b8;
	    width: 105px;
	    height: 28px;
	    color: #fff;
	    text-decoration: none;
	    font-size: 15px;
	    font-weight: 700;
	    text-align: center;
	    @include breakpoint(medium down) {
			margin-right: 0px;
			margin-bottom: 30px;
		}
	    &:last-child {
	    	margin-right: 20px;
	    }
	}
	li.topLine {
    	border-top: none;
    	border-top: 1px solid $blue1;
	}
	.qaList-content {
		margin: 50px auto;
	}
	.pic {
	    margin-right: 45px;
	    margin-left: 25px;
	    display: inline-block;
    	vertical-align: top;
	}
	.qaIn {
		display: inline-block;
		width: 372px;
    	margin-left: 70px;
    	margin-top: 28px;
    	letter-spacing: 2px;
	}
	.date {
		font-size: 16px;
    	margin-bottom: 10px;
	}
	.title {
		font-size: 17px;
	    margin-bottom: 20px;
	    line-height: 1.5;
	}
	.content {
		font-size: 14px;
		margin-right: 5px;
	    margin-bottom: 22px;
	    line-height: 27px;
	}
	.go {
		font-size: 16px;
    	color: #336699;
	}
}
.qaCotent_blue {
	background-color: #5d89b4;
	color: #fff;
	padding: 90px 0;
	@include breakpoint(medium down) {
		padding: 60px 15px 0;
	}
	.big_title {
		-webkit-writing-mode: vertical-lr;
		    -ms-writing-mode: tb-lr;
		        writing-mode: vertical-lr;
		font-size: 35px;
		position: relative;
		@include breakpoint(medium down) {
			display: none;
		}
		h1 {
			position: absolute;
			left: 65%;
			letter-spacing: 55px;
   			margin-top: 40px;
   			@include breakpoint(medium down) {
		    	position: relative;
		    	left: auto;
		    	letter-spacing: 10px;
		    }
   			&:before {
				content: "";
			    width: 60%;
			    height: 1px;
			    position: absolute;
			    bottom: 15px;
			    left: 5px;
			    background-color: #fff;
			    -webkit-transform: rotate(90deg) translate(0px, 0px);
			            transform: rotate(90deg) translate(0px, 0px);
			}
			&:after {
				content: "";
			    width: 60%;
			    height: 1px;
			    position: absolute;
			    top: -40px;
			    left: 5px;
			    background-color: #fff;
			    -webkit-transform: rotate(90deg) translate(0px, 0px);
			            transform: rotate(90deg) translate(0px, 0px);
			}
		}
	}
	.mobile-big_title {
		display: none;
		@include breakpoint(medium down) {
			display: block;
			font-weight: bold;
		}
	}
	.title {
		font-weight: 700;
		font-size: 18px;
		line-height: 1.5;
		margin-bottom: 10px;
		display: inline-block;
		position: relative;
		margin-top: 45px;
		@include breakpoint(medium down) {
			font-size: 16px;
		}
	}
	ul {
		position: relative;
		padding-bottom: 45px;
		&:first-child {
			&:after {
				content: none;
			}
		}
		&:after {
			content: "";
			width: 80px;
			height: 1px;
			position: absolute;
			top: 0;
			background-color: #fff;
		}
		li {
			line-height: 1.8;
			font-size: 14px;
			// @include breakpoint(medium down){
			// 	line-height: 2.5;
			// };
		}
	}
}
.qaContent_white {
	padding: 90px 0;
	@include breakpoint(medium down) {
		padding: 60px 15px 0;
	}
	.big_title {
		color: $blue;
		-webkit-writing-mode: vertical-lr;
		    -ms-writing-mode: tb-lr;
		        writing-mode: vertical-lr;
		font-size: 35px;
		position: relative;
		@include breakpoint(medium down) {
			display: none;
		}
		h1 {
			position: absolute;
			left: 65%;
			letter-spacing: 55px;
   			margin-top: 40px;
   			@include breakpoint(medium down) {
		    	position: relative;
		    	left: auto;
		    	letter-spacing: 10px;
		    }
			&:before {
				content: "";
			    width: 60%;
			    height: 1px;
			    position: absolute;
			    bottom: 15px;
			    left: 5px;
			    background-color: $blue;
			    -webkit-transform: rotate(90deg) translate(0px, 0px);
			            transform: rotate(90deg) translate(0px, 0px);
			}
			&:after {
				content: "";
			    width: 60%;
			    height: 1px;
			    position: absolute;
			    top: -40px;
			    left: 5px;
			    background-color: $blue;
			    -webkit-transform: rotate(90deg) translate(0px, 0px);
			            transform: rotate(90deg) translate(0px, 0px);
			}
		}
	}
	.mobile-big_title {
		display: none;
		@include breakpoint(medium down) {
			display: block;
			font-weight: bold;
			color: $blue;
		}
	}
	.title {
		font-weight: 700;
		line-height: 1.5;
		color: $blue1;
		font-size: 18px;
		margin-bottom: 10px;
		display: inline-block;
		position: relative;
		margin-top: 45px;
		@include breakpoint(medium down) {
			font-size: 16px;
		}
	}
	ul {
		position: relative;
		padding-bottom: 45px;
		&:first-child {
			&:after {
				content: none;
			}
		}
		&:after {
			content: "";
			width: 80px;
			height: 1px;
			position: absolute;
			top: 0;
			background-color: $blue;
		}
		li {
			line-height: 1.8;
			font-size: 14px;
			// @include breakpoint(medium down){
			// 	line-height: 2.5;
			// };
		}
	}
}
/*////////////======== review ========//////////////*/
.review {
	width: 100%;
	height: auto;
	background-color: #fff;
	.title {
	width: 100%;
	text-align: center;
	margin-top: 150px;
		.en {
			color: $blue;
			font-size: 17px;
			margin-top: 10px;
			margin-bottom: 20px;
			letter-spacing: 1px;
			font-family: $bree-family;
		}
	}
}
.reviewClassList {
	.reviewClass {
		margin-bottom: 50px;
		margin-top: 20px;
		@include breakpoint(medium down){
			margin-bottom: 0px;
			margin-top: 30px;
		};
	}
	.reviewNav {
		display: inline-block;
	    margin-right: 40px;
	    margin-left: 25px;
	    border-radius: 28px;
	    line-height: 28px;
	    letter-spacing: 1px;
	    background: $blue1;
	    // width: 110px;
	    // height: 28px;
	    color: #fff;
	    text-decoration: none;
	    font-size: 14px;
	    text-align: center;
	    padding: 1px 18px;
	    margin-bottom: 40px;
	    font-weight: 700;
	    @include breakpoint(medium down) {
	    	margin-right: 25px;
	    	margin-bottom: 35px;
	    }
	    &:last-child {
	    	margin-right: 20px;
	    }
	}
	li.topLine {
    	border-top: none;
    	border-top: 1px solid $blue1;
	}
	.reviewList-content {
		margin: 50px auto;
	}
	.pic {
	    margin-right: 45px;
	    margin-left: 25px;
	    display: inline-block;
    	vertical-align: top;
	}
	.qaIn {
		display: inline-block;
		width: 372px;
    	margin-left: 70px;
    	margin-top: 28px;
    	letter-spacing: 2px;
	}
	.date {
		font-size: 16px;
    	margin-bottom: 10px;
	}
	.title {
		font-size: 17px;
	    margin-bottom: 20px;
	    line-height: 1.5;
	}
	.content {
		font-size: 14px;
		margin-right: 5px;
	    margin-bottom: 22px;
	    line-height: 27px;
	}
	.go {
		font-size: 16px;
    	color: #336699;
	}
}
.reviewAll {
.reviewList {
	position: relative;
	&:nth-child(1) {
		background-image: url(..//svg/wave.svg);
	    background-repeat: no-repeat;
	    background-position: 0% 0%;
	    background-size: 100% 755px;
	    padding-top: 50px;
	    background-color: #fff !important;
	    @include breakpoint(medium down) {
	    	background-size: 150% 505px;
	    	background-position: 75% 0%;
	    }
	.information {
		margin: 50px auto;
	    padding-bottom: 50px;
	    .pic {
	    	margin-left: 60px;
	    	border-top-left-radius: 32px;
	    	@include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
	    }
	    .position {
	    	text-align: center;
		    color: #fff;
		    margin-left: 45px;
		    @include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
		    .title {
				margin-top: 35px;
		    }
		    .ch {
				font-size: 22px;
			    font-weight: 700;
			    padding-bottom: 15px;
			    border-bottom: 1px solid #fff;
			    max-width: 300px;
			    margin: 0 auto;
		    }
		    .en {
			    display: inline-block;
			    padding-top: 7px;
			    text-align: left;
			    font-size: 14px;
			    letter-spacing: 1px;
			    line-height: 25px;
		    }
		    .btn {
		    	display: inline-block;
		    	border-radius: 11px;
			    line-height: 23px;
			    letter-spacing: 2px;
			    background: #5d89b4;
			    width: 100px;
			    height: 24px;
			    display: block;
			    color: #fff;
			    font-size: 14px;
			    margin: 10px auto;
			    border: 1px solid #fff;
		    }
		    .content {
		    	font-size: 14px;
			    letter-spacing: 2px;
			    line-height: 25px;
			    display: inline-block;
			    text-align: left;
			    margin-top: 7px;
		    }
	    }
	}
	}
	&:nth-child(odd) {
		padding-top: 50px;
    	background-color: #5d88b3;
	.information {
		margin: 50px auto;
	    padding-bottom: 50px;
	    .pic {
	    	margin-left: 60px;
	    	border-top-left-radius: 32px;
	    	@include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
	    }
	    .position {
	    	text-align: center;
		    color: #fff;
		    margin-left: 45px;
		    @include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
		    .title {
				margin-top: 35px;
		    }
		    .ch {
				font-size: 22px;
			    font-weight: 700;
			    padding-bottom: 15px;
			    border-bottom: 1px solid #fff;
			    max-width: 300px;
			    margin: 0 auto;
		    }
		    .en {
			    display: inline-block;
			    padding-top: 7px;
			    text-align: left;
			    font-size: 14px;
			    letter-spacing: 1px;
			    line-height: 25px;
		    }
		    .btn {
		    	display: inline-block;
		    	border-radius: 11px;
			    line-height: 22px;
			    letter-spacing: 1px;
			    background: #5d89b4;
			    width: 100px;
			    height: 24px;
			    display: block;
			    color: #fff;
			    font-size: 13px;
			    margin: 10px auto;
			    border: 1px solid #fff;
		    }
		    .content {
		    	font-size: 14px;
			    letter-spacing: 2px;
			    line-height: 25px;
			    display: inline-block;
			    text-align: left;
			    margin-top: 7px;
		    }
	    }
	}
	}
	&:nth-child(even) {
		.information {
			margin: 50px auto;
		    padding-bottom: 50px;
	    .pic {
	    	margin-left: 60px;
	    	border-top-left-radius: 32px;
	    	@include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
	    }
	    .position {
	    	text-align: center;
		    color: $blue;
		    margin-left: 45px;
		    @include breakpoint(medium down) {
	    		margin-left: 0px;
	    	}
		    .title {
				margin-top: 35px;
		    }
		    .ch {
				font-size: 22px;
			    font-weight: 700;
			    padding-bottom: 15px;
			    border-bottom: 1px solid $blue;
			    max-width: 300px;
			    margin: 0 auto;
		    }
		    .en {
			    display: inline-block;
			    padding-top: 7px;
			    text-align: left;
			    font-size: 14px;
			    letter-spacing: 1px;
			    line-height: 25px;
		    }
		    .btn {
		    	display: inline-block;
		    	border-radius: 11px;
			    line-height: 22px;
			    letter-spacing: 1px;
			    background: #5d89b4;
			    width: 100px;
			    height: 24px;
			    display: block;
			    color: #fff;
			    font-size: 13px;
			    margin: 10px auto;
			    border: 1px solid $blue;
		    }
		    .content {
		    	font-size: 14px;
			    letter-spacing: 2px;
			    line-height: 25px;
			    display: inline-block;
			    text-align: left;
			    margin-top: 7px;
		    }
	    }
	}
	}
}
}
.reviewList2 {
    margin-top: 50px;
    padding-top: 10px;
	.information {
		margin: 50px auto;
	    padding-bottom: 50px;
	    .pic {
	    	margin-left: 60px;
	    }
	    .position {
	    	text-align: center;
		    color: $blue;
		    margin-left: 45px;
		    .title {
				margin-top: 35px;
		    }
		    .ch {
				font-size: 22px;
			    font-weight: 700;
			    padding-bottom: 15px;
			    border-bottom: 1px solid $blue;
			    max-width: 300px;
			    margin: 0 auto;
		    }
		    .en {
			    display: inline-block;
			    padding-top: 7px;
			    text-align: left;
			    font-size: 14px;
			    letter-spacing: 1px;
			    line-height: 25px;
		    }
		    .btn {
		    	display: inline-block;
		    	border-radius: 11px;
			    line-height: 23px;
			    letter-spacing: 2px;
			    background: #5d89b4;
			    width: 100px;
			    height: 24px;
			    display: block;
			    color: #fff;
			    font-size: 14px;
			    margin: 10px auto;
			    border: 1px solid $blue;
		    }
		    .content {
		    	font-size: 14px;
			    letter-spacing: 2px;
			    line-height: 25px;
			    display: inline-block;
			    text-align: left;
			    margin-top: 7px;
		    }
	    }
	}
}
/*////////////======== group1 =========//////////////*/
.group {
	width: 100%;
	height: auto;
	background-color: #fff;
	.title {
	width: 100%;
	text-align: center;
	margin-top: 150px;
		.en {
			color: $blue;
			font-size: 17px;
			margin-top: 10px;
			margin-bottom: 20px;
			letter-spacing: 1px;
			font-family: $bree-family;
		}
	}
}
.groupClass {
	text-align: center;
	.borderblue {
		margin: 0 50px;
		width: 140px;
	    height: 140px;
	    background-color: $blue1;
	    border: 2px solid $blue1;
	    border-radius: 100px;
	    padding: 6px 0;
	    margin-bottom: 17px;
	    text-align: center;
	    display: inline-block;
	    &:hover {
	    }
	    .introduce {
	    	padding: 27px 0;
	    	.position {
	    		position: relative;
			    padding-bottom: 10px;
			    margin-bottom: 10px;
			    img {
			    	height: 35px;
			    }
			    span {
			    	width: 16%;
				    height: 2px;
				    background-color: #fff;
				    display: inline-block;
				    position: absolute;
				    bottom: 0;
				    left: 42%;
			    }
	    	}
	    	.title {
	    		color: #fff;
	    	}
	    }
	}
	.borderwhite {
		margin: 0 50px;
		width: 140px;
	    height: 140px;
	    background-color: #fff;
	    border: 2px solid $blue1;
	    border-radius: 100px;
	    padding: 6px 0;
	    margin-bottom: 17px;
	    text-align: center;
	    display: inline-block;
	    &:hover {
	    	background-color: $blue1;
	    	transition: 0.1s;
	    	color: #fff;
	    	.introduce {
	    		.position {
	    			img.blue {
				    	display: none;
				    }
				    img.white {
				    	display: inline-block;
				    }
	    			span {
					    background-color: #fff;
				    }
	    		}
	    		.title {
	    			color: #fff;
	    		}
	    	}
	    }
	    .introduce {
	    	padding: 27px 0;
	    	.position {
	    		position: relative;
			    padding-bottom: 10px;
			    margin-bottom: 10px;
			    img {
			    	height: 35px;
			    }
			    img.white {
			    	display: none;
			    }
			    span {
			    	width: 16%;
				    height: 2px;
				    background-color: $blue;
				    display: inline-block;
				    position: absolute;
				    bottom: 0;
				    left: 42%;
			    }
	    	}
	    	.title {
	    		color: $blue;
	    	}
	    }
	}
}
.intro {
	margin: 80px auto;
	.left {
	}
	.right {
		text-align: justify;
		line-height: 30px;
		font-size: 14px;
		@include breakpoint(medium down) {
	    	margin-top: 50px;
	    	padding: 0 50px;
	    	text-align: center;
	    }
	}
}
.topWhite {
	text-align: center;
	.num {
		position: relative;
		font-size: 43px;
		padding-top: 35px;
		padding-bottom: 10px;
		display: inline-block;
		&:after {
			content: "";
		    bottom: 0;
		    position: absolute;
		    width: 50%;
		    height: 1px;
		    background-color: #fff;
		    left: 27%;
		}
	}
	.title {
		margin: 15px auto;
		font-size: 23px;
	}
}
.topBlue {
	text-align: center;
	color: $blue;
	.num {
		position: relative;
		font-size: 43px;
		padding-top: 35px;
		padding-bottom: 10px;
		display: inline-block;
		&:after {
			content: "";
		    bottom: 0;
		    position: absolute;
		    width: 50%;
		    height: 1px;
		    background-color: $blue;
		    left: 27%;
		}
	}
	.title {
		margin: 15px auto;
		font-size: 23px;
	}
}
.contentWhite {
	text-align: center;
	margin: 53px auto;
	font-size: 14px;
	line-height: 35px;
}
.contentBlue {
	text-align: center;
	margin: 53px auto;
	font-size: 14px;
	line-height: 35px;
}
.one {
	background-color: $blue-deep;
	color: #fff;
	.oneList {
		margin: 0px auto;
		ul {
			margin-left: 25px;
			li {
				min-width: 200px;
				font-size: 14px;
				padding-left: 35px;
    			margin: 27px auto;
    			position: relative;
    			span {
    				position: absolute;
				    left: -20px;
				    width: 20px;
				    height: 20px;
				    background-color: #faaf3b;
				    border-radius: 20px;
				    text-align: center;
				    display: inline-block;
				    font-size: 16px;
				    vertical-align: middle;
				    padding-left: 2px;
				    bottom: -3px;
    			}
			}
		}
		.oneClass {
			font-size: 21px;
		}
	}
	.oneList2 {
		margin: 0px auto;
		ul {
			margin-left: 25px;
			li {
				min-width: 200px;
				font-size: 14px;
				padding-left: 35px;
    			margin: 27px auto;
    			position: relative;
    			span {
    				position: absolute;
				    left: -20px;
				    width: 20px;
				    height: 20px;
				    background-color: #00a79b;
				    border-radius: 20px;
				    text-align: center;
				    display: inline-block;
				    font-size: 16px;
				    vertical-align: middle;
				    padding-left: 2px;
				    bottom: -3px;
    			}
			}
		}
		.oneClass {
			font-size: 21px;
		}
	}
	.map {
		text-align: center;
		padding: 40px 0;
	}
}
.two {
	.top {
		text-align: center;
	}
	.num {
	}
	.title {
	}
}
.intro2 {
	max-width: 1280px;
	margin: 55px auto;
	.content {
		text-align: left;
		line-height: 30px;
		font-size: 14px;
	}
}
.three {
	position: relative;
	background-color: $blue-deep;
	color: #fff;
	padding-top: 45px;
	ul {
		@include breakpoint(medium down) {
			padding: 30px 50px;
		}
	}
	.content {
		position: absolute;
	    right: 5%;
	    top: 10%;
	    line-height: 30px;
	    font-size: 14px;
	    @include breakpoint(medium down) {
	    	position: relative;
	    	right: auto;
	    	text-align: center;
	    }
	}
	.pic {
		img {
			width: 100%;
		}
	}
}
.four {
	position: relative;
	text-align: center;
	@include breakpoint(medium down) {
		padding: 0 50px;
	}
	.content {
		line-height: 35px;
		span {
			font-weight: 700;
			color: $blue-deep;
		}
	}
	.rooms {
		img {
			width: 100%;
		}
	}
}
.five {
	.contentWhite {
		font-weight: 700;
		color: $blue-deep;
	}
	.rooms {
		margin: 75px auto;
		text-align: center;
		.rooms-intro {
			margin-bottom: 25px;
			img {
				width: 100%;
			}
			.title {
				margin: 15px auto;text-align: left;width: 95%;
			}
		}
	}
}
/*////////////======== group2 =========//////////////*/

.group2List {
	width: 860px;
	margin: 0 auto 100px;
	.top-list-title {
		border-bottom: 1px solid #5d89b4;
		padding: 20px 0;
		background-color: #5d89b4;
		color: #fff;
		text-align: center;
		@include breakpoint(medium down) {
			display: none;
		}
		.top-room {
			width: 170px;
		}
		.top-price {
			width: 300px;
		}
		.top-reservation {
			width: 176px;
		}
	}
	.list-title {
		background-color: $blue-deep;
		color: #fff;
	}
	.list-menu {
		border-bottom: 1px solid #5d89b4;
		padding: 33px 0;
		line-height: 1.5;
		@include breakpoint(medium down) {
			padding: 20px;
			padding-bottom: 35px;
			text-align: center;
		}
	}
	.room {
		width: 170px;
		color: $blue-deep;
		@include breakpoint(medium down) {
			font-weight: bold;
		}
	}
	.price {
		width: 300px;
		@include breakpoint(medium down) {
			font-size: 15px;
		}
	}
	.reservation {
		width: 176px;
	}
	@include breakpoint(medium down) {
		div {
			padding: 20px 0;
		}
	}
	.other-rooms {
		margin: 35px 35px;
		text-align: center;
		a {
			color: #6786b8;
		}
	}
}
/*////////////======== group3 =========//////////////*/
/*////////////======== footer ========//////////////*/
footer {
    background-color: #5d89b4;
    padding: 45px 0 93px;
    @include breakpoint(medium down){
    	padding: 40px 0 60px;
    };
}
.footerWrap {
    max-width: 864px;
    margin: 0 auto;
    padding: 40px 0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    color: #fff;
    line-height: 27px;
    letter-spacing: 1px;
    position: relative;
    @include breakpoint(medium down) {
    	margin: 0px 15px;
    	padding: 10px 0;
    }
    img {
    	@include breakpoint(medium down) {
    		display: none;
    	}
    }
    &:after {
    	content: "";
    	position: absolute;
    	width: 100%;
    	height: 1px;
    	background-color: #fff;
    	bottom: -7px;
    }
    .address {
		font-size: 13px;
		@include breakpoint(medium down) {
	    	border-bottom: 1px solid #fff;
	    	padding-bottom: 10px;
	    }
		/* .ch {*/
		/* 	font-size: 12px;*/
		/* }*/
    }
    .contact {
		font-size: 13px;
    	letter-spacing: 1px;
    	padding-left: 30px;
    	@include breakpoint(medium down) {
    		padding-left: 0px;
    		padding-top: 10px;
    	}
    }
}
.footericon {
	margin-bottom: 15px;
	@include breakpoint(medium down){
		margin: 0 15px 15px;
	};
	.title{
		font-family: $en-family;
		color: #fff;
		margin-right: 30px;
		@include breakpoint(medium down){
			margin-right: 0;
			margin-bottom: 10px;
		};
	}
	.linkList{
		a{}
	}
}
/*////////////======== slick slider ========//////////////*/
.index-bannerSliderWarp {
	/* max-width: 1200px;*/
	background-color: $blue1;
	margin: -1px auto;
	position: relative;
	.bannerSlider {
		margin-bottom: 0px;
		padding-bottom: 55px;
		@include breakpoint(medium down){
			padding-bottom: 36px;
		};
		.slide {
			padding: 10px;
			position: relative;
			max-height: 463px;
			img {
				width: 100%;
			}
			.text {
				position: absolute;
				left: 35px;
    			bottom: 25px;
    			@include breakpoint(medium down) {
    				display: none;
    			}
    			.en {
					color: #fff;
					font-size: 38px;
    				line-height: 65px;
    				font-family: $bree-family;
    				@include breakpoint(medium down) {
    					font-size: 19px;
					    margin-bottom: 5px;
					    display: inline-block;
					    border-bottom: 1px solid;
					    line-height: initial;
					    width: 30%;
    				}
				}
				.ch {
					color: #fff;
    				font-size: 17px;
    				@include breakpoint(medium down) {
    					font-size: 20px;
    				}
				}
			}
		}
	}
	.slick-dots{
		bottom: 0;
	}
	.slick-dots li button:before {
		content: "";
	    opacity: 1;
	    width: 10px;
	    height: 10px;
	    border: 2px solid #fff;
	    border-radius: 20px;
	    position: absolute;
	}
	.slick-dots li.slick-active button:before {
		opacity: 1;
		background-color: #fff;
	}
}
.news-bannerSliderWarp {
	/* max-width: 1200px;*/
	margin: 0 auto;
	position: relative;
	.bannerSlider {
		margin-bottom: 100px;
		padding-bottom: 30px;
		@include breakpoint(medium down) {
			margin-bottom: 55px;
			padding-bottom: 15px;
		}
		.slide {
			padding: 10px;
			position: relative;
			img {
				width: 100%;
			}
			.text {
				position: absolute;
				left: 10px;
    			bottom: 10px;
    			background-color: #5b89b4;
    			border-top-right-radius: 50px;
    			padding: 18px 50px;
    			@include breakpoint(medium down) {
    				display: none;
    			}
    			.en {
					color: #fff;
					font-size: 38px;
    				line-height: 45px;
    				font-family: $bree-family;
				}
				.ch {
					color: #fff;
    				line-height: 50px;
    				font-size: 20px;
				}
			}
		}
	}
	.slick-dots li button:before {
	    width: 12px;
	    height: 12px;
	}
}
.rooms-bannerSliderWarp {
	/* max-width: 1200px;*/
	margin: 0 auto;
	position: relative;
	margin-top: 120px;
	@include breakpoint(medium down) {
		margin-top: 20px;
	}
	.bannerSlider {
		margin-bottom: 50px;
		padding-bottom: 30px;
		@include breakpoint(medium down) {
			margin-bottom: 40px;
    		padding-bottom: 10px;
		}
		.slide {
			padding: 10px;
			position: relative;
			img {
				width: 100%;
				/* max-height: 370px;*/
			}
			.text {
				position: absolute;
				left: 35px;
    			bottom: 20px;
				.ch {
					color: #fff;
    				font-size: 38px;
    				font-family: $jk;
    				@include breakpoint(medium down) {
    					font-size: 21px;
    				}
				}
				.en {
					color: #fff;
					font-size: 20px;
    				font-family: $bree-family;
    				margin: 12px auto;
    				@include breakpoint(medium down) {
    					font-size: 13px;
    					margin: 3px auto;
    				}
				}
			}
		}
	}
}
.amenities-bannerSliderWarp {
	margin: 0 auto;
	position: relative;
	margin-top: 120px;
	@include breakpoint(medium down) {
		margin-top: 40px;
	}
	.bannerSlider {
		margin-bottom: 160px;
	    padding-bottom: 0;
		@include breakpoint(medium down){
			margin-bottom: 100px;
			padding-bottom: 6px;
		};
		.slide {
			padding: 7px;
			img {
				width: 100%;
				/* max-height: 370px;*/
			}
		}
		.slick-dots {
			bottom: -60px;
			@include breakpoint(medium down){
				bottom: -40px;
			};
		}
	}
}
.attractions-bannerSliderWarp {
	margin: 0 auto;
	position: relative;
	.bannerSlider {
		margin-bottom: 110px;
		padding-bottom: 30px;
		@include breakpoint(medium down){
			padding-bottom: 10px;
		};
		.slide {
			padding: 10px;
			position: relative;
			img {
				width: 100%;
				/* max-height: 370px;*/
			}
			.text {
				position: absolute;
				left: 40px;
			    bottom: 40px;
    			@include breakpoint(medium down) {
    				display: none;
    			}
    			.en {
					color: #fff;
					font-size: 26px;
    				line-height: 65px;
    				font-family: $bree-family;
    				@include breakpoint(medium down) {
    					font-size: 19px;
					    margin-bottom: 5px;
					    display: inline-block;
					    border-bottom: 1px solid;
					    line-height: initial;
					    width: 30%;
    				}
				}
				.ch {
					color: #fff;
    				font-size: 22px;
    				@include breakpoint(medium down) {
    					font-size: 20px;
    				}
				}
			}
		}
	}
}
.review-bannerSliderWarp {
	margin: 0 auto;
	position: relative;
	.bannerSlider {
		margin-bottom: 110px;
		padding-bottom: 0;
		@include breakpoint(medium down) {
			margin-bottom: 65px;
    		padding-bottom: 0px;
		}
		.slide {
			padding: 10px;
			img {
				width: 100%;
			}
		}
	}
	.slick-dots {
		bottom: -60px;
		@include breakpoint(medium down){
			bottom: -38px;
		};
	}
}
.group-bannerSliderWarp {
	/* max-width: 1200px;*/
	margin: 0 auto;
	position: relative;
	.bannerSlider {
		margin-bottom: 100px;
		padding-bottom: 30px;
		@include breakpoint(medium down){
			padding-bottom: 10px;
		};
		.slide {
			padding: 10px;
			img {
				width: 100%;
			}
		}
	}
}
/* dots //*/
.slick-prev, .slick-next {
	z-index: 1;
}
.slick-prev {
    left: 260px;
    @include breakpoint(medium down){
		left: 2%;
    	top: 38%;
	};
}
.slick-next {
    right: 260px;
    @include breakpoint(medium down){
    	right: 8%;
    	top: 38%;
	};
}
.slick-prev:before {
	content:"";
    background-image: url('..//svg/arrow-prev.svg');
    background-size: 20px 43px;
    width: 20px;
    height: 43px;
    position: absolute;
    background-repeat: no-repeat;
	opacity: 1;
	@include breakpoint(medium down){
		background-size: 20px 30px;
	}
}
.slick-next:before {
	content:"";
	background-image: url('..//svg/arrow-next.svg');
	background-size: 20px 43px;
    width: 20px;
    height: 43px;
    position: absolute;
    background-repeat: no-repeat;
	opacity: 1;
	@include breakpoint(medium down){
		background-size: 20px 30px;
	}
}
.slick-dots li button:before {
	content: "";
    opacity: 1;
    width: 10px;
    height: 10px;
    border: 2px solid $blue-deep;
    border-radius: 20px;
    position: absolute;
}
.slick-dots li.slick-active button:before {
	opacity: 1;
	background-color: $blue-deep;
}
.slide.slick-slide .text{
	opacity: 0;
	transition: 0.3s ease-in-out;
}
.slide.slick-slide.slick-current .text{
	opacity: 1;
	transition: 0.3s ease-in-out;
}
/*////////////======== mobile ========//////////////*/
@media all and (max-width: 1920px) {
	.slick-prev {
	    left: 485px;
	    top: 42%;
	}
	.slick-next {
	    right: 505px;
	    top: 42%;
	}
}
@media all and (max-width: 1680px) {
	.slick-prev {
	    left: 325px;
	}
	.slick-next {
	    right: 355px;
	}
}
@media all and (max-width: 1366px) {
	.slick-prev {
	    left: 285px;
	}
	.slick-next {
	    right: 305px;
	}
}
@media all and (max-width: 1280px) {
	.slick-prev {
	    left: 235px;
	    top: 40%;
	}
	.slick-next {
	    right: 255px;
	    top: 40%;
	}
   .floor-1 {
   		width: auto;
   }
   .floor-2 {
   		width: auto;
   }
}
@media all and (max-width: 1024px) {
	.slick-prev {
	    left: 15px;
	    top: 35%;
	}
	.slick-next {
	    right: 35px;
	    top: 35%;
	}
	.slick-prev:before,
	.slick-next:before {
		background-size: 20px 25px;
	}
    .group2List {
		width: 100%;
		margin: 0 auto;
		.list-title {
			display: none;
		}
	}
}
.btn-1 {
	text-decoration: none;
  	line-height: 190px;
	position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: 140px;
    max-width: 140px;
    margin: 1rem auto;
    text-transform: uppercase;
    border: 2px solid currentColor;
    border-radius: 50%;
  	text-align: center;
  	color: $blue1;
  	transition: 0.4s ease-in-out;
  	margin: 0 50px;
  	margin-top: 30px;
    margin-bottom: 20px;
  	img {
  		padding-top: 30px;
  		/* margin: 0 auto;*/
  		/* position: relative;*/
  		position: absolute;
    	left: 31%;
	    width: 50px;
	    height: 67px;
  	}
  	.blue {
  		opacity: 1;
  		transition: 0.4s ease-in-out;
  	}
  	.white {
  		opacity: 0;
  		transition: 0.4s ease-in-out;
  	}
  	&:before {
	    content: '';
	    position: absolute;
	    top: 0;
	    right: -50px;
	    bottom: 0;
	    left: 0;
	    border-right: 50px solid transparent;
	    border-bottom: 140px solid $blue1;
	    -webkit-transform: translateX(-100%);
	    transform: translateX(-100%);
	    z-index: -1;
	    transition: 0.3s ease-in-out;
	}
	&:after {
  		content: "";
		position: absolute;
		width: 15%;
		height: 2px;
		background-color: #6786b8;
		bottom: 60px;
		left: 42%;
		transition: 0.4s ease-in-out;
  	}
  	&:hover {
   		color: #fff;
   		transition: 0.3s ease-in-out;
   	.blue {
  		opacity: 0;
  		transition: 0.4s ease-in-out;
  	}
  	.white {
  		opacity: 1;
  		transition: 0.4s ease-in-out;
  	}
    	&:before {
    		-webkit-transform: translateX(0);
    		        transform: translateX(0);
    		z-index: -1;
    		transition: 0.3s ease-in-out;
    	}
    	&:after {
			background-color: #fff;
			transition: 0.4s ease-in-out;
	  	}
  	}
}
.btn-1.current{
	background-color: $blue1;
	color: #fff;
	.blue {
  		opacity: 0;
  	}
  	.white {
  		opacity: 1;
  	}
  	&:after {
		background-color: #fff;
	}
}
#googleMap{
	width: 1200px;
	height: 400px;
	background-color: #eee;
	margin: 0 auto;
	@include breakpoint(medium down){
		width: 100%;
		height: 300px;
	};
}
.contactForm{
	width: 860px;
    margin: 50px auto 100px;
    border: 1px solid #6786b8;
    padding: 0 40px 50px;
    position: relative;
    @include breakpoint(medium down){
    	width: 100%;
    	border: none;
    	padding: 0 30px 40px;
    	margin: 30px auto 30px;
    	&:after{display: none;}
    };
    &:after{
    	content: '';
    	position: absolute;
    	top: -7px;
    	right: -7px;
    	bottom: -7px;
    	left: -7px;
    	border: 1px solid #6786b8;
    	z-index: -1;
    }
    .is-twofield{
    	.item:first-child{
    		margin-right: 70px;
    	}
    }
    .head{
    	font-family: $content-family;
    	font-size: 18px;
    	letter-spacing: 2px;
    	color: #fff;
    	text-align: center;
    	background-color: #6786b8;
    	padding: 20px;
    	margin: 0 -40px 30px;
    	@include breakpoint(medium down){
    		font-size: 14px;
    		padding: 15px 20px;
    	};
    }
    #send{
    	text-align: center;
    	margin-top: 50px;
    	cursor: pointer;
    	span{
    		font-family: $content-family;
    		font-size: 15px;
    		color: #fff;
    		background-color: #6786b8;
    		border-radius: 200px;
    		padding: 2px 26px 3px;
    	}
    }
	.item{
		border-bottom: 1px solid #6786b8;
		margin-bottom: 33px;
		padding-bottom: 4px;
		position: relative;
		.contact-form-error{
			font-size: 12px;
			font-style: italic;
			color: red;
			white-space: nowrap;
			position: absolute;
			top: 5px;
			right: 0;
		}
	}
	.title{
		font-family: $content-family;
		font-size: 14px;
		letter-spacing: 3px;
		line-height: 1.5;
		color: #6786b8;
		white-space: nowrap;
		margin-right: 12px;
	}
	input[type='text']{
		font-family: $content-family;
		font-size: 14px;
		letter-spacing: 1px;
		color: #000;
		width: 100%;
		border: none;
		background: transparent;
	}
	/*圖片式*/
	input[type="radio"]{
		display: none;
	}
	input[type="radio"] + label{
		font-family: $content-family;
		font-size: 14px;
		letter-spacing: 3px;
		line-height: 1.5;
		color: #6786b8;
		white-space: nowrap;
		display:inline-block;
		vertical-align: middle;
		cursor: pointer;
		margin-right: 12px;
		background:url(../images/radiobg.svg) left center no-repeat;
		cursor: pointer;
		margin-left: 50px;
		padding-left: 20px;
		@include breakpoint(medium down){
			margin-left: 30px;
		};
	}
	input[type="radio"]:checked + label{
		background:url(../images/radiobg2.svg) left center no-repeat;
	}
	input[type="checkbox"]{
		display: none;
	}
	input[type="checkbox"] + label{
		font-family: $content-family;
		font-size: 14px;
		letter-spacing: 3px;
		line-height: 1.5;
		color: #6786b8;
		white-space: nowrap;
		display:inline-block;
		vertical-align: middle;
		cursor: pointer;
		margin-right: 12px;
		background:url(../images/radiobg.svg) left center no-repeat;
		cursor: pointer;
		margin-left: 50px;
		padding-left: 20px;
		@include breakpoint(medium down){
			margin-left: 30px;
		};
	}
	input[type="checkbox"]:checked + label{
		background:url(../images/radiobg2.svg) left center no-repeat;
	}
}
.douneedroom{
	font-family: $content-family;
	font-size: 18px;
	letter-spacing: 1px;
	color: #6786b8;
	position: relative;
	display: inline-block;
	margin-bottom: 30px;
	.needtitle{
		margin-bottom: 8px;
	}
	.note{
		font-size: 12px;
	}
	.plus{
		position: absolute;
		top: auto;
		left: auto;
		right: -27px;
		bottom: 0;
	}
}
#indexnewscontentsliderWrap{
	@include breakpoint(medium down){
		display: none;
	};
}
#indexnewscontentslider{
	max-width: 748px;
	margin: 0 auto;
}
.rrrrrrrrrrrrrrrlist{
	section{
		padding: 60px 0;
		@include breakpoint(medium down){
			padding: 46px 0;
		};
		&:nth-child(odd){
			background-color: #fff;
			.ch{
				color: #5b89b4;
				border-bottom: 1px solid #5b89b4;
			}
			.en{
				color: #5b89b4;
			}
			.btn{
				color: #5b89b4;
				border: 1px solid #5b89b4;
			}
		}
		&:nth-child(even){
			background-color: #5d88b3;
			.ch{
				color: #fff;
				border-bottom: 1px solid #fff;
			}
			.en{
				color: #fff;
			}
			.btn{
				color: #fff;
				border: 1px solid #fff;
			}
		}
		&:last-child{
			padding-bottom: 100px;
		}
	}
	.rrrrrrrrrrrcontainer{
		max-width: 900px;
		margin: 0 auto;
		@include breakpoint(medium down){
			padding: 0 30px;
		};
	}
	.pic{
		margin-right: 190px;
		border-top-left-radius: 30px;
		overflow: hidden;
		@include breakpoint(medium down){
			margin-right: 0;
			margin-bottom: 30px;
		};
	}
	.article-area{
		text-align: center;
		width: 212px;
	}
	.ch{
		font-family: $content-family;
		font-weight: 700;
		font-size: 21px;
		letter-spacing: 1px;
		line-height: 1.3;
		padding-bottom: 15px;
		margin-bottom: 15px;
	}
	.en{
		font-family: $content-family;
		font-size: 14px;
		line-height: 1.3;
		margin-bottom: 45px;
	}
	.btn{
		font-family: $content-family;
		font-size: 14px;
		border-radius: 10px;
		padding: 2px 8px;
	}
}
#traveladvicer{
	@extend .rrrrrrrrrrrrrrrlist;
	position: relative;
	// background: url(../images/reviewwwwwwave.svg) 0 0 / 100% 100% no-repeat;
	background-color: #5d88b3;
	padding: 70px 0;
	text-align: center;
	.pic{}
	.ch{
		color: #fff;
		border-bottom: 1px solid #fff;
	}
	.en{
		color: #fff;
	}
	.btn{
		color: #fff;
		border: 1px solid #fff;
	}
}
#addddwrap{
	position: absolute;
	top: 90px;
	right: 0;
	display: inline-block;
	@include breakpoint(medium down){
		position: relative;
		top: 0;
		margin-top: 30px;
	};
}
#contactFinishAnchor{
	font-family: $content-family;
	font-size: 30px;
	letter-spacing: 2px;
	line-height: 1.8;
	color: #000;
	text-align: center;
    padding: 0 30px;
    margin: 40px auto 100px;
    @include breakpoint(medium down){
    	font-size: 16px;
    };
}
#mymapWrap{
	overflow: hidden;
	position: relative;
	width: 1200px;
	margin: 0 auto -46px;
	@include breakpoint(medium down){
		width: 100%;
	};
	/* fade css */
	.flickity-slider {
		transform: none !important;
	}
	iframe{
		position: relative;
		top: -46px;
		/* fade css */
		left: 0 !important;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		z-index: -1;
		@include breakpoint(medium down){
			height: 300px;
		};
		&.is-selected {
			opacity: 1;
			z-index: 0
		}
	}
}
.booknow{
	z-index: 19;
	position: fixed;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	left: 30px;
	cursor: pointer;
	width: 37px;
	font-family: $content-family;
	font-size: 16px;
	color: #fff;
	background-color: #6786b8;
	padding: 18px 11px 13px;
	border-radius: 200px;
	line-height: 1.3;
	@include breakpoint(medium down){
		display: none;
	};
	img{
		margin-top: 12px;
	}
}
.ryder-amenitiesWrap{
	article{
		padding-bottom: 90px;
		@include breakpoint(large down){
			padding-bottom: 52px;
		};
		&:nth-child(odd){
			background-color: #5b89b4;
			.floor{
				margin-left: 30%;
				@include breakpoint(large down){
					margin-left: 0;
					text-align: center;
				};
				span{
					color: #fff;
					border-color: #fff;
				}
			}
			.place-container{
				@include breakpoint(xlarge){
					section{
						&:nth-child(odd){
							.pic{}
							.article-area{
								position: absolute;
								right: 0;
								bottom: 0;
							}
						}
						&:nth-child(even){
							.pic{
								text-align: right;
							}
							.article-area{
								position: absolute;
								left: 0;
								bottom: 0;
							}
						}
					}
				};
				.article-area{
					.title-area{
						.ch, .en{
							color: #fff;
							border-color: #fff;
						}
					}
					.content{
						color: #fff;
					}
				}
			}
		}
		&:nth-child(even){
			background-color: #fff;
			.floor{
				margin-left: 70%;
				@include breakpoint(large down){
					margin-left: 0;
					text-align: center;
				};
				span{
					color: #5b89b4;
					border-color: #5b89b4;
				}
			}
			.place-container{
				@include breakpoint(xlarge){
					section{
						&:nth-child(odd){
							.pic{
								text-align: right;
							}
							.article-area{
								position: absolute;
								left: 0;
								bottom: 0;
							}
						}
						&:nth-child(even){
							.pic{}
							.article-area{
								position: absolute;
								right: 0;
								bottom: 0;
							}
						}
					}
				};
				.article-area{
					.title-area{
						.ch, .en{
							color: #5b89b4;
							border-color: #5b89b4;
						}
					}
					.content{
						color: #5b89b4;
					}
				}
			}
		}
	}
	.floor{
	    padding-top: 90px;
	    margin-bottom: 90px;
	    @include breakpoint(large down){
	    	padding-top: 50px;
	    	margin-bottom: 60px;
	    };
	    span{
    		font-family: $en-family;
    		font-size: 46px;
    		letter-spacing: 5px;
    		border: 2px solid #fff;
    		border-radius: 50%;
    		display: inline-block;
    		width: 120px;
    		height: 120px;
    		text-align: center;
    		line-height: 120px;
    		padding-left: 5px;
    	    @include breakpoint(large down){
    	    	font-size: 49px;
    	    	letter-spacing: 9px;
    	    	border-width: 3px;
    	    };
	    }
	}
	.place-container{
		position: relative;
		max-width: 1220px;
		margin: 0 auto;
		@include breakpoint(1300px down){
			max-width: 1050px;
		};
		section{
			position: relative;
			@include mb(90px);
			@include breakpoint(large down){
				@include mb(50px);
			};
		}
	}
	.pic{
		@include breakpoint(1300px down){
			img{
				max-width: 800px;
			}
		};
		@include breakpoint(large down){
			margin-bottom: 50px;
			img{
				max-width: 100%;
			}
		};
	}
	.article-area{
		max-width: 300px;
		@include breakpoint(large down){
			max-width: 100%;
		    padding: 0 40px;
		};
		.title-area{
			margin-bottom: 20px;
			.ch{
				font-family: $content-family;
				font-weight: 700;
				font-size: 28px;
				letter-spacing: 4px;
				margin-right: 4px;
				margin-bottom: 12px;
				position: relative;
				left: -3px;
			}
			.en{
				font-family: $en-family;
				font-weight: 700;
				font-size: 17px;
				border-bottom: 2px solid;
				padding-bottom: 3px;
				display: inline-block;
			}
		}
		.content{
			font-family: $content-family;
			font-size: 14px;
			letter-spacing: 1px;
			line-height: 2;
			max-width: 240px;
			@include breakpoint(medium down){
				max-width: 100%;
			};
		}
	}
}
.ryder-bannerSliderWarp{
	margin-bottom: 120px;
	@include breakpoint(medium down){
		margin-top: 20px;
		margin-bottom: 70px;
	};
	/* reset */
	.flickity-button {
		background-color: transparent;
		&:hover {
		    background: transparent;
		}
		&:focus {
		    outline: none;
  			box-shadow: none;
		}
		&:active {
		    opacity: 1;
		}
		&:disabled {
		    opacity: .3;
		    cursor: auto;
		    pointer-events: none
		}
	}
	/* previous & next buttons */
	.flickity-prev-next-button {
		width: 70px;
		height: 70px;
		@include breakpoint(medium down){
			width: 40px;
			height: 40px;
		};
		&.previous{
			left: 25.5%;
			@include breakpoint(medium down){
				left: 10px;
			};
		}
		&.next{
			right: 25.5%;
			@include breakpoint(medium down){
				right: 10px;
			};
		}
		/* svg */
		.flickity-button-icon {
			fill: #fff;
		}
	}
	/* pager dot -----------------------------------------------------------------------------------------*/
	/* position dots in carousel */
	.flickity-page-dots {
		bottom: -45px;
		left: 0;
		@include breakpoint(medium down){
			bottom: -35px;
		};
	}
	/* white circles */
	.flickity-page-dots .dot {
		display: inline-block;
		vertical-align: middle;
		width: 12px;
		height: 12px;
		background-color: transparent;
		border: 2px solid #5b89b4;
		border-radius: 50%;
		opacity: 1;
		transition: all .5s;
		@include mr(14px);
	}
	/* fill-in selected dot */
	.flickity-page-dots .dot.is-selected {
		opacity: 1;
		background-color: #5b89b4;
	}
}
.ryder-bannerSlider{
	@include breakpoint(medium down){
		padding: 0 10px;
	};
	li{
		position: relative;
		width: 50%;
		margin-right: 20px;
		@include breakpoint(medium down){
			width: 100%;
		};
	}
	.img-container{
		padding-top: 47.14%;
	}
	.text {
		position: absolute;
		left: 50px;
		bottom: 20px;
		@include breakpoint(medium down) {
			display: none;
		}
		.en {
			color: #fff;
			font-size: 38px;
			line-height: 45px;
			font-family: $ench;
		}
		.ch {
			font-family: $ench;
			color: #fff;
			line-height: 50px;
			font-size: 20px;
		}
	}
}
.ryder-index-contentSlider{
	max-width: 748px;
	margin: 100px auto 0;
	li{
		width: 100%;
	}
	.content{
		color: #fff;
		font-size: 15px;
		letter-spacing: 2px;
		line-height: 2;
	}
}
.ryder-index-slider-outWrap{
	background-color: #5d89b4;
	padding: 1px;
	.btn{
		border-radius: 30px;
	    line-height: 28px;
	    letter-spacing: 1px;
	    background: #fff;
	    width: 145px;
	    height: 28px;
	    display: block;
	    color: #6786b8;
	    font-size: 16px;
	    margin: 50px auto 0;
	    font-family: $en-family;
	    @include breakpoint(medium down){
	    	margin-top: 80px;
	    };
	}

	.ryder-bannerSliderWarp{
		margin-top: 0;
		margin-bottom: 60px;
		/* white circles */
		.flickity-page-dots .dot {
			border: 2px solid #fff;
		}
		/* fill-in selected dot */
		.flickity-page-dots .dot.is-selected {
			background-color: #fff;
		}
	}
}
.m-title{
	padding: 55px 0 50px;
	text-align: center;
	@include breakpoint(medium down){
		padding: 5px 0 25px;
	};
	&.is-index{
		padding: 55px 0 35px;
		background-color: #6786b8;
		.ch{
			color: #fff;
			&:before, &:after{
				background-color: #fff;
			}
		}
		.en{
			color: #fff;
		}
	}
	.ch{
		font-family: $content-family;
		font-weight: 700;
		font-size: 24px;
		letter-spacing: 6px;
		color: #6786b8;
		margin-bottom: 17px;
		display: inline-block;
		position: relative;
		&:before, &:after{
			content: '';
			width: 27px;
			height: 1px;
			background-color: #5b89b4;
			position: absolute;
			top: 50%;
			left: -50px;
		}
		&:after{
			left: auto;
			right: -43px;
		}
	}
	.en{
		font-family: $en-family;
		font-size: 18px;
		color: #5b89b4;
	}
	.note{
		color: #6786b8;
		letter-spacing: 4px;
		font-size: 17px;
		margin-top: 20px;
	}
}
.group3title {
	font-size: 24px;
	letter-spacing: 4px;
	color: #6786b8;
	padding: 30px 0 20px;
	font-weight: 700;
}
.group2title {
	font-size: 24px;
	letter-spacing: 4px;
	color: #6786b8;
	padding: 30px 0 50px;
	font-weight: 700;
	text-align: center;
}
#visorrrrr{
	max-width: 900px;
	margin: 50px auto 0;
	@include breakpoint(medium down){
		padding: 0 30px;
	};
}
.ryder-location-outwrap{
	margin: 60px auto 100px;
	@include breakpoint(medium down){
		padding: 0 20px;
	};
}
.ryder-location-container{
	max-width: 1400px;
	margin: 0 auto 50px;
	@include breakpoint(xlarge down){
		max-width: 1100px;
	};
	.main-container{
		border-bottom: 1px solid #6786b8;
		padding: 0 50px 50px;
		@include breakpoint(medium down){
			padding: 0 0 50px;
		};
		.pic{
			width: 490px;
			margin-left: 12.6%;
			@include breakpoint(xlarge down){
				margin-left: 0;
			};
			@include breakpoint(medium down){
				width: 100%;
				margin-bottom: 30px;
			};
		}
		.article-area{
			width: 500px;
			padding-left: 85px;
			text-align: center;
			@include breakpoint(medium down){
				width: 100%;
				padding-left: 0;
			};
			.ch{
				font-family: $content-family;
				font-weight: 700;
				font-size: 24px;
				letter-spacing: 1px;
				color: #6786b8;
				margin-bottom: 15px;
			}
			.en{
				font-family: $en-family;
				font-size: 14px;
				letter-spacing: 1px;
				color: #6786b8;
				border-top: 1px solid #6786b8;
				padding-top: 12px;
				margin-bottom: 30px;
				display: inline-block;
			}
			.btn{
				margin: -10px auto 20px;
				a{
					font-family: $en-family;
					font-size: 14px;
					letter-spacing: 1px;
					color: #6786b8;
					border: 1px solid #6786b8;
					border-radius: 20px;
					padding: 1px 16px;
				}
			}
			.content{
				font-family: $content-family;
				font-size: 14px;
				letter-spacing: 1px;
				line-height: 2;
				color: #6786b8;
			}
			.map{
				margin-top: 14px;
				a{
					font-family: $en-family;
					font-size: 14px;
					letter-spacing: 1px;
					color: #6786b8;
					border: 1px solid #6786b8;
					border-radius: 20px;
					padding: 1px 16px;
				}
			}
		}
	}
}
.ryder-trafficList{
	li{
		padding: 50px;
		border-bottom: 1px solid #6786b8;
		@include breakpoint(medium down){
			padding: 50px 0;
		};
	}
	.title-area{
		width: 190px;
		@include breakpoint(medium down){
			margin: 0 0 30px;
			width: 100%;
			text-align: center;
		};
		.title{
			font-family: $content-family;
			font-weight: 700;
			font-size: 21px;
			line-height: 1.3;
			color: #6786b8;
			margin-bottom: 15px;
		}
		.no{
			font-family: $content-family;
			font-size: 15px;
			line-height: 1.5;
			color: #6786b8;
			margin-bottom: 15px;
		}
		.site{
			a{
				font-family: $content-family;
				font-size: 14px;
				letter-spacing: 1px;
				color: #6786b8;
				border: 1px solid #6786b8;
				border-radius: 20px;
				padding: 1px 16px;
			}
		}
	}
	.content{
		font-family: $content-family;
		font-size: 14px;
		letter-spacing: 1px;
		line-height: 2;
		color: #666;
		margin: 0 50px;
		@include breakpoint(medium down){
			margin: 0 0 30px;
			// text-align: center;
			width: 100%;
		};
	}
	.price{
		font-family: $content-family;
		font-size: 14px;
		letter-spacing: 1px;
		line-height: 2;
		color: #666;
		width: 220px;
	    word-break: break-word;
		@include breakpoint(medium down){
			// text-align: center;
			width: 100%;
		};
	}
}
.ryder-topmenu-book{
	position: absolute;
	top: 11px;
    left: 30px;
    cursor: pointer;
    border-radius: 200px;
    border: 2px solid #6381B0;
    background-color: #6381B0;
    padding: 2px 13px;
    transition: all .5s;
    @include breakpoint(medium down){
    	top: 17px;
    	left: 20px;
    };
    &:hover{
    	background-color: #fff;
    	svg{
    		.st0 {
    		    fill: #6381B0;
    		}
    		.st1 {
    		    fill: #fff;
    		}
    	}
    }
	svg{
		width: 130px;
		height: auto;
		margin-bottom: -2px;
		@include breakpoint(medium down){
			width: 80px;
		};
		.st0 {
		    fill: #FFFFFF;
		    transition: all .5s;
		}
		.st1 {
		    fill: #6381B0;
		    transition: all .5s;
		}
	}
}
.ryder-select-catWrap{
	margin-top: 30px;
	margin-bottom: 50px;
	display: inline-block;
	.my-select{
		float: none;       /* 重點 */
		font-family: $content-family;
		font-size: 15px;
		letter-spacing: 1px;
		color: #fff;
		text-align: center !important;      /* 要強制 */
		background-color: #5b89b4;
		border: 1px solid #5b89b4;
		border-radius: 200px;
		min-width: 240px;
		height: 34px;
		line-height: 31px;
		&:hover, &:focus{
			border: 1px solid $blue;
		}
		/* 點開的選單 */
		.list{
			width: 100%;
			border: 2px solid #5b89b4;
			background-color: #5b89b4;
		}
	}
}